import { oneMinute, reportApiClient } from "../ApiClient";

class LocalReportApi {
    async list({ projectId, pageNumber, pageLimit, feature, scenario }) {
        const resp = await reportApiClient.get(`/api/report/local/${projectId}`, {
            params: {
                pageNumber: pageNumber,
                pageLimit: pageLimit,
                feature: feature,
                scenario: scenario,
            },
        }, { timeout: 2 * oneMinute });
        return resp.data;
    }

    async getReport({ runId }) {
        const resp = await reportApiClient.get(`/api/report/local/run/${runId}`);
        return resp.data;
    }

    async detail({ runId, resultId }) {
        const resp = await reportApiClient.get(`/api/report/local/run/${runId}/${resultId}`);
        return resp.data;
    }

    async getSavedReportDetail({ username, runId, resultId }) {
        const resp = await reportApiClient.get(`/api/report/local/saved/${username}/${runId}/${resultId}`);
        return resp.data;
    }

    async getSavedReport({ username, runId }) {
        const resp = await reportApiClient.get(`/api/report/local/saved/details/${username}/${runId}/`);
        return resp.data;
    }

    saveLocalReport({ username, runId }) {
        return reportApiClient.post(
            `/api/report/local/saved/add`,
            {
                username,
                runId,
            },
            { timeout: oneMinute }
        );
    }

    deleteLocalReport({ username, runId }) {
        return reportApiClient.delete(`/api/report/local/saved/delete/${username}/${runId}`, { timeout: oneMinute });
    }

    async getSavedLocalReports(data) {
        const resp = await reportApiClient.post(`/api/report/local/saved`, {
            username: data.ownerId,
            pageNumber: data.pageNumber,
            pageSize: data.pageSize,
            projectIds: data.projectId ? [data.projectId] : null,
            fromDate: data.fromDate,
            toDate: data.toDate,
            language: data.language,
        }, { timeout: 2 * oneMinute });
        return resp.data;
    }

    async exportLocalReports(data) {
        const resp = await reportApiClient.post(`/api/report/local/saved/export`, {
            username: data.ownerId,
            pageNumber: data.pageNumber,
            pageSize: data.pageSize,
            projectIds: data.projectId ? [data.projectId] : null,
            fromDate: data.fromDate,
            toDate: data.toDate,
            language: data.language,
        }, { timeout: 2 * oneMinute });
        return resp.data;
    }
}

export default new LocalReportApi();
