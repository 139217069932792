import { dashApiClient, maxTimeout } from "../ApiClient";

class ProjectApi {
    async getAll(projectGroupId) {
        const resp = await dashApiClient.get(`/api/projects`, {
            params: {
                projectGroupId: projectGroupId,
            },
        });
        return resp?.data;
    }

    async getOne(projectId) {
        const resp = await dashApiClient.get(`/api/projects/${projectId}`);
        return resp.data;
    }

    async getTeams() {
        const resp = await dashApiClient.get(`/api/projects/teams`);
        return resp.data.map((team) => ({
            label: team.name,
            value: team.id,
        }));
    }

    async getTemplates() {
        const resp = await dashApiClient.get(`/api/projects/templates`);
        return resp.data.map((template) => ({
            label: template.name,
            value: template.id,
        }));
    }

    async getImportable() {
        const resp = await dashApiClient.get(`/api/projects/importable`, {
            timeout: maxTimeout,
        });
        return resp.data;
    }

    async getNamespaces({ internal }) {
        const resp = await dashApiClient.get(`/api/projects/namespaces`, {
            params: {
                internal,
            },
        });
        return resp.data.map((namespace) => ({
            label: namespace,
            value: namespace,
        }));
    }

    async getLastCommit({ projectId }) {
        const resp = await dashApiClient.get(`/api/projects/${projectId}/lastCommit`);
        return resp.data;
    }

    async findLastCommitByBranch({ projectId, branchName }) {
        const branchNameQuery = branchName ? "?branchName=" + branchName :""
        const resp = await dashApiClient.get(`/api/projects/${projectId}/last-commit-by-branch${branchNameQuery}`);
        return resp.data.data;
    }

    create({ namespace, name, owner, projectGroup, projectTemplate, isTemplate }) {
        const path = `${namespace}/${name.trim().toLowerCase().replace(/\s+/g, "-")}.git`;
        return dashApiClient.post(`/api/projects`, {
            name: name,
            path: path,
            ownerId: owner,
            projectGroupId: projectGroup,
            templateProjectId: projectTemplate,
            template: isTemplate,
        });
    }

    import({ owner, projectGroup, paths }) {
        return dashApiClient.post(`/api/projects/import`, paths, {
            params: {
                projectGroupId: projectGroup,
                ownerId: owner,
            },
            timeout: maxTimeout,
        });
    }

    edit({ id, name, owner, projectGroup, isTemplate }) {
        return dashApiClient.put(`/api/projects`, {
            id: id,
            name: name,
            ownerId: owner,
            projectGroupId: projectGroup,
            template: isTemplate,
        });
    }

    delete({ id, deleteFromGit }) {
        return dashApiClient.delete(`/api/projects/${id}`, {
            params: {
                deleteFromGit: deleteFromGit,
            },
        });
    }

    async addToFavorites({projectId}) {
        return dashApiClient.post(`/api/projects/${projectId}/favorite`);
    }

    async deleteFromFavorites({projectId}) {
        return dashApiClient.delete(`/api/projects/${projectId}/favorite`);
    }

    async getFileContent({ id, path }) {
        const resp = await dashApiClient.get(`/api/projects/${id}/files/${path}`);
        return resp.data;
    }


}

export default new ProjectApi();
