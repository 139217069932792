import { Button, Checkbox, Col, Input, Modal, Radio, Row } from "antd";
import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import ReactDOM from "react-dom";
import { kuklaUrl } from "../../api/ApiClient";
import withErrorHandler from "../../util/WithErrorHandler";

const getBugReportUrl = (projectId, desc, includeLogs, includeTestType) => {
    let params = "?includeLogs=" + includeLogs + "&";
    if (desc) {
        params += "desc=" + desc + "&";
    }
    if (projectId) {
        params += "projectId=" + projectId + "&";
    }
    params += "includeTestType=" + includeTestType;
    return kuklaUrl + "/settings/bugReport" + params;
};

// TODO: not project specific reports (like settings of project list in kukla-ui)
const BugReport = ({ t, visible, hideCallback, projectId }) => {
    const [description, setDescription] = useState();
    const [includeLogs, setIncludeLogs] = useState(true);
    const [radioTestValue, setRadioTestValue] = useState(!projectId ? 0 : 2);
    const [lockIncludeLogs, setLockIncludeLogs] = useState(false);

    const onRadioChange = (e) => {
        const value = e.target.value;
        ReactDOM.unstable_batchedUpdates(() => {
            setRadioTestValue(value);
            value === 0 && setIncludeLogs(true);
            setLockIncludeLogs(value === 0);
        });
    };

    const onCancel = () => {
        ReactDOM.unstable_batchedUpdates(() => {
            setDescription();
            setRadioTestValue(2);
            setIncludeLogs(true);
            setLockIncludeLogs(false);
        });
        hideCallback();
    };

    const onDownload = () => {
        const a = document.createElement("a");
        a.href = getBugReportUrl(projectId, description, includeLogs, radioTestValue);
        a.download = "";
        a.click();
        onCancel();
    };

    return (
        <Modal
            title={t("Bug Report")}
            width={(window.innerWidth / 100) * 25}
            visible={visible}
            onCancel={onCancel}
            footer={[
                <Button key="cancel" onClick={onCancel}>
                    {t("Cancel")}
                </Button>,
                <Button key="download" type="primary" onClick={onDownload}>
                    {t("Download")}
                </Button>,
            ]}
        >
            <div>
                <Row>
                    <Col span={7}>{t("Description")}: </Col>
                    <Col>
                        <Input
                            value={description}
                            placeholder="default: bug-report-yyyyMMdd-HHmmss"
                            onChange={(e) => setDescription(e.target.value)}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Checkbox
                            onChange={(e) => setIncludeLogs(e.target.checked)}
                            defaultChecked
                            checked={includeLogs}
                            disabled={lockIncludeLogs}
                        >
                            {t("Include logs")}
                        </Checkbox>
                    </Col>
                </Row>
                <Row>
                    <Radio.Group onChange={onRadioChange} value={radioTestValue} disabled={!projectId}>
                        <Radio className="bug-report-radio-button" value={0}>
                            {t("No tests")}
                        </Radio>
                        <Radio className="bug-report-radio-button" value={1}>
                            {t("Last test")}
                        </Radio>
                        <Radio className="bug-report-radio-button" value={2}>
                            {t("All tests")}
                        </Radio>
                    </Radio.Group>
                </Row>
            </div>
        </Modal>
    );
};

export default withErrorHandler(withTranslation()(BugReport));
