import { createContext, useContext, useState } from "react";

const KuklaContext = createContext({});

export function useKuklaContext() {
    return useContext(KuklaContext);
}

export function KuklaProdiver({ children }) {
    const [connectedToKukla, setConnectedToKukla] = useState(false);
    const [kuklaRemoteEnabled, setKuklaRemoteEnabled] = useState(false);

    return (
        <KuklaContext.Provider value={{ connectedToKukla, updateKuklaConnection: setConnectedToKukla, kuklaRemoteEnabled, setKuklaRemoteEnabled }}>
            {children}
        </KuklaContext.Provider>
    );
}

// Use only for testing
export const TestKuklaContext = KuklaContext;
