import loglevel from "loglevel";
import LogApi from "../api/dash/LogApi";
import KuklaLogApi from "../api/kukla/KuklaLogApi";
import KuklaHealthApi from "../api/kukla/KuklaHealthApi";

loglevel.enableAll();

const levels = {
    ERROR: "error",
    WARN: "warn",
    INFO: "info",
    TRACE: "trace",
    DEBUG: "debug",
};

const getStackTrace = () => {
    return new Error().stack.substring(5);
};

const createLog = (level, msg, includeStackTrace) => {
    let logDetails = {
        name: msg[0],
        message: msg.slice(1).join("\n"),
        level: level,
        stackTrace: includeStackTrace ? getStackTrace() : "",
    };
    KuklaHealthApi.check()
        .then(() => KuklaLogApi.saveLog(logDetails))
        .catch(() => LogApi.saveLog(logDetails));
};

export const CustomLog = {
    getStackTrace: getStackTrace,

    trace: (...msg) => {
        loglevel.trace(...msg);
        createLog(levels.TRACE, msg, false);
    },

    debug: (...msg) => {
        loglevel.debug(...msg);
        createLog(levels.DEBUG, msg, false);
    },

    info: (...msg) => {
        loglevel.info(...msg);
        createLog(levels.INFO, msg, false);
    },

    warn: (...msg) => {
        loglevel.warn(...msg);
        createLog(levels.WARN, msg, false);
    },

    error: (...msg) => {
        loglevel.error(...msg);
        createLog(levels.ERROR, msg, false);
    },

    errorTrace: (...msg) => {
        loglevel.error(...msg);
        createLog(levels.ERROR, msg, true);
    },
};
