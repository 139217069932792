import { dashApiClient } from "../ApiClient";

class LogApi {
    async saveLog(data) {
        const resp = await dashApiClient.post(`/api/logs/save`, data).catch(() => void 0);
        return resp?.data;
    }
}

export default new LogApi();
