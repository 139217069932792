import {
    Alert,
    Button,
    Card,
    Col,
    Collapse,
    Descriptions,
    Divider,
    Modal,
    Row,
    Select,
    Space,
    Statistic,
    Typography
} from "antd";
import {
    BarChartOutlined,
    CheckCircleTwoTone,
    CloseCircleTwoTone,
    DownloadOutlined,
    QuestionCircleTwoTone,
    RightCircleTwoTone
} from "@ant-design/icons";

import { withTranslation } from "react-i18next";
import { useApi } from "../../hook/UseApi";
import RunApi from "../../api/dash/RunApi";
import React, { useEffect, useMemo, useState } from "react";
import moment from "moment";
import _ from "lodash";
import { useUserContext } from "../../context/UserContext";
import LocalReportApi from "../../api/report/LocalReportApi";
import { dashUrl, reportUrl } from "../../api/ApiClient";
import withErrorHandler from "../../util/WithErrorHandler";
import { createTag, runDataMapper } from "./RunUtil";
import { downloadFile } from "../../util/Utils";
import { getLSLanguage } from "../../util/LocalStorageHandler";

const { Text } = Typography;

const RunResultModal = ({ t, local, savedLocal, runId, visible, hideCallback, address }) => {
    const { user } = useUserContext();
    const [selectedScenarioResult, setSelectedScenarioResult] = useState("FAILED");
    const [filterSelectedScenarioResult, setFilterSelectedScenarioResult] = useState("dateTime");
    const [run, setRun] = useState();
    const [selectedScenario, setSelectedScenario] = useState(address);

    const scenarioRef = useMemo(() => React.createRef(), []);

    const filterOptions = [
        { label: t("All Scenarios"), value: "ALL" },
        { label: t("Failed Scenarios"), value: "FAILED" },
        { label: t("Success Scenarios"), value: "PASSED" },
        { label: t("Unstable Scenarios"), value: "UNSTABLE" },
        { label: t("Aborted Scenarios"), value: "ABORTED" },
    ];

    const sortOptions = [
        { label: t("Scenario Name"), value: "name" },
        { label: t("Scenario Run Time"), value: "dateTime" },
        { label: t("File Path and Line"), value: "file" },
    ];

    // set run as server run
    useApi({
        name: "fetching run result",
        api: RunApi.getRun,
        onSuccess: setRun,
        autoLoad: visible && !local && !savedLocal && runId,
        params: { id: runId },
    });

    // set run as local run
    useApi({
        name: "fetching local run result",
        api: LocalReportApi.getReport,
        onSuccess: (data) => setRun(runDataMapper(data)),
        autoLoad: visible && local && !savedLocal && runId,
        params: { runId: runId },
    });

    // set run as saved local run
    useApi({
        name: "fetching saved local run result",
        api: LocalReportApi.getSavedReport,
        onSuccess: (data) => setRun(runDataMapper(data)),
        autoLoad: visible && !local && savedLocal && runId,
        params: { runId: runId, username: savedLocal },
    });

    const runResultLoader = useApi({
        name: "fetching run result",
        api: RunApi.getRunResult,
        autoLoad: visible && !local && !savedLocal && (run?.id || run?.runId),
        params: { runId: run?.id ? run.id : run?.runId },
    });

    const runResults = useMemo(() => {
        if (!run) return [];
        else if (run?.results) return run.results;
        else return runResultLoader.data;
    }, [run, runResultLoader.data]);

    // to set scnearioStep using by LOCAL run result
    const localRunResultStepsLoader = useApi({
        name: "fetching local run result steps",
        api: LocalReportApi.detail,
    });

    // to set scnearioStep using by SERVER run result
    const runResultStepsLoader = useApi({
        name: "fetching run result steps",
        api: RunApi.getRunResultScenario,
    });

    // to set scnearioStep using by saved local run result
    const savedLocalRunResultStepsLoader = useApi({
        name: "fetching saved local run result steps",
        api: LocalReportApi.getSavedReportDetail,
    });

    const onExportSuccess = (resp) => {
        const fileName = `Run_Result_${run?.id}_${run?.jobName}`;
        downloadFile(resp, fileName, fileName + "-" + moment(new Date().getTime()).format("DDMMYYYY-HHmmss"));
    };

    const exportRunResultsLoader = useApi({
        name: "exporting run result",
        api: RunApi.exportRunResult,
        onSuccess: onExportSuccess
    });

    let onSortFilter = (runResult) => {
        const array = [];
        let filterText = "";

        array.push(runResult.id.split(":"));
        filterText = filterText + array[0][1] + array[0][2];
        return filterText.toLowerCase();
    };

    const scenarioSteps = useMemo(() => {
        if (local && !savedLocal) return localRunResultStepsLoader.data;
        else if (!local && savedLocal) return savedLocalRunResultStepsLoader.data;
        else return runResultStepsLoader.data;
    }, [
        local,
        localRunResultStepsLoader.data,
        runResultStepsLoader.data,
        savedLocal,
        savedLocalRunResultStepsLoader.data,
    ]);

    const scenarioSortandFilter = useMemo(() => {
        return selectedScenarioResult !== "ALL"
            ? _.filter(
                  _.orderBy(
                      runResults,
                      filterSelectedScenarioResult !== "file"
                          ? [filterSelectedScenarioResult]
                          : (runResult) => onSortFilter(runResult),
                      ["asc"]
                  ),
                  {
                      resultType: selectedScenarioResult,
                  }
              )
            : _.orderBy(
                  runResults,
                  filterSelectedScenarioResult !== "file"
                      ? [filterSelectedScenarioResult]
                      : (runResult) => onSortFilter(runResult),
                  ["asc"]
              );
    }, [runResults, filterSelectedScenarioResult, selectedScenarioResult]);

    const title = (
        <>
            <BarChartOutlined />
            {local
                ? " " + t("Local Run Result")
                : savedLocal
                ? " " + t("Saved Local Run Result")
                : " " + t("Run Result") + " : #" + run?.id + " - " + run?.jobName}
        </>
    );

    const createName = (id, name) => {
        let ext = name.substring(name.lastIndexOf("."));
        if(id.includes("."+ext)){
            return id
        }else
        {
            return id + ext;
        }
       
    };

    const getSource = (fileName) =>
        local
            ? `${reportUrl}/api/report/local/${run?.key}/attachments/${fileName}?userName=${user.username}`
            : savedLocal
            ? `${reportUrl}/api/report/local/saved/${savedLocal}/${run?.startTime}/attachments/${fileName}`
            : `${dashUrl}/api/runs/${run?.id}/attachments/${fileName}`;

    const onScenarioPanelChange = (key) => {
        setSelectedScenario(key);
        if (key && runId && runId !== undefined) {
            local
                ? localRunResultStepsLoader.execute({ runId, resultId: key })
                : savedLocal
                ? savedLocalRunResultStepsLoader.execute({ username: savedLocal, runId, resultId: key })
                : runResultStepsLoader.execute({ runId, scenarioId: key });
        }
    };

    useEffect(() => {
        if (address && scenarioSortandFilter?.length) {
            const scenario = scenarioSortandFilter.find(item => item.id === address);
            if(scenario) {
                onScenarioPanelChange(address);
                scenarioRef?.current?.scrollIntoView({
                    behavior: 'smooth',
                    block: "start",
                    inline: "end"
                });
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [address, scenarioSortandFilter, scenarioRef]);

    return (
        <Modal
            title={title}
            visible={visible}
            width={window.innerWidth * 0.8}
            onCancel={() => hideCallback()}
            destroyOnClose
            footer={null}
        >
            <Card>
                <Row>
                    <Col className="text-align-center" flex={1 / 4}>
                        <Statistic title={t("SCENARIO")} value={runResults?.length} />
                    </Col>

                    <Col className="text-align-center" flex={1 / 4}>
                        <Statistic title={t("PASSED")} value={_.filter(runResults, { resultType: "PASSED" }).length} />
                    </Col>
                    <Col className="text-align-center" flex={1 / 4}>
                        <Statistic
                            title={t("UNSTABLE")}
                            value={_.filter(runResults, { resultType: "UNSTABLE" }).length}
                        />
                    </Col>
                    <Col className="text-align-center" flex={1 / 4}>
                        <Statistic title={t("FAILED")} value={_.filter(runResults, { resultType: "FAILED" }).length} />
                    </Col>
                </Row>
            </Card>
            {(run?.id && !savedLocal) && <Button
                key="export"
                type="primary"
                style={{ float: "right", marginBottom: "12px" }}
                disabled={run?.runResult?.state !== "PASSED"}
                loading={exportRunResultsLoader.loading}
                size="small"
                icon={<DownloadOutlined />}
                onClick={() => exportRunResultsLoader.execute({ runId: run?.id ?? run?.runId, params: { lang: getLSLanguage() } })}
            >
                {t("Export")}
            </Button>}
            <Descriptions size="small" bordered column={2}>
                <Descriptions.Item key="state" label={t("State")}>
                    {createTag(t, run?.runResult.state)}
                </Descriptions.Item>
                <Descriptions.Item key="by" label={t("Executed By")}>
                    {run?.userName}
                </Descriptions.Item>
                <Descriptions.Item key="start" label={t("Start Time")}>
                    {moment(run?.startTime).format("DD.MM.YYYY HH:mm")}
                </Descriptions.Item>
                <Descriptions.Item key="duration" label={t("Duration")}>
                    {moment.utc(moment.duration(run?.duration, "milliseconds").asMilliseconds()).format("HH:mm:ss")}
                </Descriptions.Item>
            </Descriptions>
            {runResults && runResults.length > 0 && (
                <>
                    <Divider>
                        <Text>{t("Filter By")}:</Text>
                        <Select
                            className="filter-sort-select-item"
                            value={selectedScenarioResult}
                            options={filterOptions}
                            onChange={setSelectedScenarioResult}
                        />
                        <Text className={"filter-label-item"}>{t("Sort By")}:</Text>
                        <Select
                            className="filter-sort-select-item"
                            value={filterSelectedScenarioResult}
                            options={sortOptions}
                            onChange={setFilterSelectedScenarioResult}
                        />
                    </Divider>

                    <Collapse
                        className="collapse-run-result"
                        accordion
                        expandIconPosition="right"
                        onChange={onScenarioPanelChange}
                        activeKey={selectedScenario}
                    >
                        {scenarioSortandFilter?.map((item) => {
                            return (
                                <Collapse.Panel
                                    header={
                                        <Row className="full-width">
                                            <Col span={1} className="left-placement">
                                                <Row className="collapse-header">
                                                    {item.resultType === "FAILED" ? (
                                                        <CloseCircleTwoTone twoToneColor="#FF2400" />
                                                    ) : item.resultType === "PASSED" ? (
                                                        <CheckCircleTwoTone twoToneColor="#32CD32" />
                                                    ) : (
                                                        <QuestionCircleTwoTone />
                                                    )}{" "}
                                                </Row>
                                            </Col>
                                            <Col span={11}>
                                                <div ref={item.id === address ? scenarioRef : undefined} >
                                                    <Typography.Text>{item?.name}</Typography.Text>
                                                </div>
                                                <div>
                                                    <Typography.Text className="secondary-text">
                                                        {item?.id.split(":")[1]}
                                                    </Typography.Text>
                                                </div>
                                            </Col>
                                            <Col span={5}>
                                                <Typography.Text className="collapse-header">
                                                    {t("Duration")}:
                                                    {moment
                                                        .utc(
                                                            moment
                                                                .duration(item.duration, "milliseconds")
                                                                .asMilliseconds()
                                                        )
                                                        .format("HH:mm:ss")}
                                                </Typography.Text>
                                            </Col>
                                            <Col span={5}>
                                                <Typography.Text className="collapse-header">
                                                    {t("Retry Count")}: {item.retryNumber ? item.retryNumber : 0}
                                                </Typography.Text>
                                            </Col>
                                        </Row>
                                    }
                                    key={item?.id}
                                    showArrow={false}
                                >
                                    <Space className="run-result-modal-step-details" direction="vertical">
                                        <Space wrap>
                                            {scenarioSteps?.attachments?.map((item) => (
                                                <Button
                                                    key={item.fileName}
                                                    target="_blank"
                                                    href={getSource(item.fileName)}
                                                >
                                                    {createName(item.deviceId, item.fileName)}
                                                </Button>
                                            ))}
                                        </Space>
                                        {scenarioSteps?.errorText && (
                                            <Alert
                                                message={t("Error Message")}
                                                description={scenarioSteps?.errorText}
                                                type="error"
                                                showIcon
                                                key="alert"
                                            />
                                        )}

                                        {scenarioSteps?.stepResults && (
                                            <Collapse key="collapse-step">
                                                <Collapse.Panel header={t("Scenario Steps")}>
                                                    {scenarioSteps?.stepResults.map((step, index) => {
                                                        return (
                                                            <div key={index} className="step-container">
                                                                {step.resultType === "PASSED" ? (
                                                                    <CheckCircleTwoTone twoToneColor="#32CD32" />
                                                                ) : step.resultType === "FAILED" ? (
                                                                    <CloseCircleTwoTone twoToneColor="#FF2400" />
                                                                ) : (
                                                                    <RightCircleTwoTone />
                                                                )}{" "}
                                                                {step.line}
                                                                <div>
                                                                    <Typography.Text className="secondary-text">
                                                                        {t("Duration")}:{" "}
                                                                        {moment
                                                                            .utc(
                                                                                moment
                                                                                    .duration(
                                                                                        step?.duration,
                                                                                        "milliseconds"
                                                                                    )
                                                                                    .asMilliseconds()
                                                                            )
                                                                            .format("HH:mm:ss")}
                                                                    </Typography.Text>
                                                                </div>
                                                            </div>
                                                        );
                                                    })}
                                                </Collapse.Panel>
                                            </Collapse>
                                        )}
                                    </Space>
                                </Collapse.Panel>
                            );
                        })}
                    </Collapse>
                </>
            )}
        </Modal>
    );
};

export default withErrorHandler(withTranslation()(RunResultModal));
