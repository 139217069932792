import { dashApiClient } from "../ApiClient";
import moment from "moment";

class ProjectGroupApi {
    async summary() {
        const resp = await dashApiClient.get(`/api/projects/groups/summary`);
        return resp.data.map((projectGroup) => ({
            label: projectGroup.name,
            value: projectGroup.id,
        }));
    }
    async getProjectGroupList() {
        const resp = await dashApiClient.get(`/api/projects/groups`);
        return resp?.data;
    }

    createProjectGroup(data) {
        data.startedAt = moment(data?.startedAt).format("DD/MM/YYYY");
        return dashApiClient.post(`/api/projects/groups`, data);
    }

    updateProjectGroup(data) {
        data.startedAt = moment(data?.startedAt).format("DD/MM/YYYY");
        return dashApiClient.put(`/api/projects/groups`, data);
    }

    deleteProjectGroup(id) {
        return dashApiClient.delete(`/api/projects/groups/${id}`);
    }
}

export default new ProjectGroupApi();
