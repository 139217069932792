import { Popconfirm, Switch } from "antd";
import { withTranslation } from "react-i18next";
import withErrorHandler from "../../util/WithErrorHandler";
import {
    CloudUploadOutlined,
    LaptopOutlined
  } from '@ant-design/icons';
import useLocalStorage from "../../hook/UseLocalStorage";
import WorkspaceStatusEnum from "../../api/constant/WorkspaceStatusEnum";

const KuklaStatusSwitch = ({ t }) => {
    
    const [workspaceStatus, setWorkspaceStatus] = useLocalStorage("workspaceStatus", "0");

    const onClick = (event) => {
        console.log(event);
    }

    const onChange = (value) => {
        console.log(value);
    }

    const confirm = (event) => {
        console.log(event);
        setWorkspaceStatus(workspaceStatus === WorkspaceStatusEnum.LOCAL ? WorkspaceStatusEnum.REMOTE : WorkspaceStatusEnum.LOCAL);
        window.location.reload();
    }

    const cancel = (event) => {
        console.log(event);
    }

    return (
        <>
            <Popconfirm
                title={t('Do you want to change workspace environment to ' + (workspaceStatus === WorkspaceStatusEnum.LOCAL ? 'Remote' : 'Local') + '?')}
                description="Environment change"
                onConfirm={confirm}
                onCancel={cancel}
                okText={t('Yes')}
                cancelText={t('No')}
            >
                <Switch 
                    checkedChildren={<CloudUploadOutlined />}
                    unCheckedChildren={<LaptopOutlined />}
                    defaultChecked={workspaceStatus === WorkspaceStatusEnum.LOCAL ? false : true}
                    onClick={onClick}
                    onChange={onChange}
                    checked={workspaceStatus === WorkspaceStatusEnum.REMOTE}/>
            </Popconfirm>
        </>
    );
};

export default withErrorHandler(withTranslation()(KuklaStatusSwitch));
