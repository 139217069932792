import { createContext, useCallback, useContext, useMemo, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { useLocation } from "react-router";
import { useKuklaContext } from "./KuklaContext";
import useModalControl from "../hook/UseModalControl";

const ProjectContext = createContext({});

export function useProjectContext() {
    return useContext(ProjectContext);
}

export function ProjectProvider({ _projectId, _projectName, children }) {
    const { connectedToKukla } = useKuklaContext();
    const location = useLocation();

    // TODO: remove state if setters are never called
    const [projectId, setProjectId] = useState(_projectId);
    const [projectName, setProjectName] = useState(_projectName);

    const [kuklaInfo, setKuklaInfo] = useState();
    const [files, setFiles] = useState([]);

    const clonedQuery = new URLSearchParams(location.search).get("cloned");
    const cloned = useMemo(
        () => (kuklaInfo ? kuklaInfo.cloned : clonedQuery ? clonedQuery === "true" : undefined),
        [clonedQuery, kuklaInfo]
    );

    // Global variable for editMode. DO NOT USE in EditorPage
    const clonedAndConnected = useMemo(() => connectedToKukla && cloned, [connectedToKukla, cloned]);

    // Should be used only in EditorPage.
    const [editMode, setEditMode] = useState(connectedToKukla && cloned);

    const [selectedFile, setSelectedFile] = useState();
    const [selectedFileType, setSelectedFileType] = useState();
    const [monacoRef, setMonacoRef] = useState();
    const [modules, setModules] = useState();
    const stepDocVer = useRef(-1);

    const [leftPanelExpandedFeatureKeys, setLeftPanelExpandedFeatureKeys] = useState([]);
    const [leftPanelExpandedDocumentKeys, setLeftPanelExpandedDocumentKeys] = useState([]);

    const [leftPanelMenuSelectedKeys, setLeftPanelMenuSelectedKeys] = useState(["feature"]);
    const [rightPanelMenuSelectedKeys, setRightPanelMenuSelectedKeys] = useState([]);
    // right panel expanded accordion key
    const [activeDetailKey, setActiveDetailKey] = useState();

    // tab panel
    const [panes, setPanes] = useState([]);

    // conflict & diff editor props
    const [selectedFileConflictCause, setSelectedFileConflictCause] = useState();
    const [conflictingFiles, setConflictingFiles] = useState(null);
    const [selectedDiffFile, setSelectedDiffFile] = useState();
    const [conflictMode, setConflictMode] = useState(null);
    const [conflictCount, setConflictCount] = useState(-1);
    const [selectedFileConflictStatus, setSelectedFileConflictStatus] = useState(""); // solved | notSolved | notConflicted

    const updateSelectedFile = useCallback((selectedFile, selectedFileType) => {
        ReactDOM.unstable_batchedUpdates(() => {
            setSelectedFile(selectedFile);
            setSelectedFileType(selectedFileType);
        });
    }, []);

    const updateConflictStatus = useCallback((conflictMode, conflictCount) => {
        ReactDOM.unstable_batchedUpdates(() => {
            setConflictMode(conflictMode);
            setConflictCount(conflictCount);
        });
    }, []);

    const searchModalControl = useModalControl();

    return (
        <ProjectContext.Provider
            value={{
                editMode,
                setEditMode,
                projectId,
                setProjectId,
                projectName,
                setProjectName,
                selectedFile,
                setSelectedFile,
                selectedFileType,
                setSelectedFileType,
                updateSelectedFile,
                monacoRef,
                setMonacoRef,
                modules,
                setModules,
                stepDocVer,
                cloned,
                conflictMode,
                setConflictMode,
                conflictCount,
                setConflictCount,
                updateConflictStatus,
                kuklaInfo,
                setKuklaInfo,
                selectedFileConflictStatus,
                setSelectedFileConflictStatus,
                panes,
                setPanes,
                files,
                setFiles,
                selectedDiffFile,
                setSelectedDiffFile,
                searchModalControl,
                clonedAndConnected,
                conflictingFiles,
                setConflictingFiles,
                selectedFileConflictCause,
                setSelectedFileConflictCause,
                activeDetailKey,
                setActiveDetailKey,
                leftPanelMenuSelectedKeys,
                setLeftPanelMenuSelectedKeys,
                rightPanelMenuSelectedKeys,
                setRightPanelMenuSelectedKeys,
                leftPanelExpandedFeatureKeys,
                setLeftPanelExpandedFeatureKeys,
                leftPanelExpandedDocumentKeys,
                setLeftPanelExpandedDocumentKeys,
            }}
        >
            {children}
        </ProjectContext.Provider>
    );
}

// Use only for testing
export const TestProjectContext = ProjectContext;
