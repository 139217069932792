import { dashApiClient } from "../ApiClient";

class JobApi {
    async getAll() {
        const resp = await dashApiClient.get(`/api/jobs`);
        return resp?.data;
    }

    async getJobInformation({ projectId, projectGroupId, ownerId, scheduleType }) {
        const resp = await dashApiClient.get(`/api/jobs/info`, {
            params: {
                projectId,
                projectGroupId,
                ownerId,
                scheduleType,
            },
        });
        return resp.data;
    }

    createJob(data) {
        return dashApiClient.post(`/api/jobs`, data);
    }

    updateJob(data) {
        return dashApiClient.put(`/api/jobs`, data);
    }

    async getOne(jobId) {
        const resp = await dashApiClient.get(`/api/jobs/${jobId}`);
        return resp.data;
    }

    deleteJob(id) {
        return dashApiClient.delete(`/api/jobs/${id}`);
    }

    async addToFavorites(jobId) {
        return dashApiClient.post(`/api/jobs/${jobId}/favorite`);
    }

    async deleteFromFavorites(jobId) {
        return dashApiClient.delete(`/api/jobs/${jobId}/favorite`);
    }

    setNotificationSettings(data) {
        return dashApiClient.put(`/api/jobs/${data.jobId}/notificationSettings`, data);
    }

    async getNotificationSettings(data) {
        const resp = await dashApiClient.get(`/api/jobs/${data.id}/notificationSettings`);
        return resp.data;
    }
}

export default new JobApi();
