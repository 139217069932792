import { kuklaApiClient } from "../ApiClient";

class KuklaLogApi {
    async saveLog(data) {
        const resp = await kuklaApiClient.post(`/logs/save`, data).catch(() => void 0);
        return resp?.data;
    }
}

export default new KuklaLogApi();
