import { createContext, useContext } from "react";
import { setApiUser } from "../api/ApiClient";
import useLocalStorage from "../hook/UseLocalStorage";

const USER_KEY = "user";
const UserContext = createContext({});

export function useUserContext() {
    return useContext(UserContext);
}

function parseUser(userStr) {
    if (!userStr) return null;
    try {
        const user = JSON.parse(userStr);
        if (user) {
            let parsedAccessToken = JSON.parse(Buffer.from(user.access_token.split(".")[1], "base64"));
            user.id = parsedAccessToken?.userId;
            user.git_token = parsedAccessToken?.gitToken;
            user.git_token_refresh_at = parsedAccessToken?.gitTokenRefreshAt;
            user.user_token = parsedAccessToken?.userToken;
        }
        return user;
    } catch (e) {
        console.log("Cannot parse user", userStr, e);
        return null;
    }
}

export function UserProdiver({ children }) {
    const [userStr, setUserStr] = useLocalStorage(USER_KEY);
    const user = parseUser(userStr);
    setApiUser(user);

    function updateUser(newUser) {
        setUserStr(newUser ? JSON.stringify(newUser) : null);
    }

    return <UserContext.Provider value={{ user, updateUser }}>{children}</UserContext.Provider>;
}

// Use only for testing
export const TestUserContext = UserContext;
