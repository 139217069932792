import { dashApiClient, oneMinute } from "../ApiClient";

class RunApi {
    runJob(jobId) {
        return dashApiClient.post(`/api/runs`, {
            jobId: jobId,
        });
    }
    async getRuns({ jobId, projectGroupId, jobOwnerId, projectId, feature, scenario }) {
        const resp = await dashApiClient.get(`/api/runs`, {
            params: {
                jobId: jobId,
                projectId: projectId,
                projectGroupId: projectGroupId,
                jobOwnerId: jobOwnerId,
                feature: feature,
                scenario: scenario,
            },
        });
        return resp.data;
    }

    async getQueuedList() {
        const resp = await dashApiClient.get(`/api/runs/queuedList`);
        return resp.data;
    }

    async getRunGraph({ jobId }) {
        const resp = await dashApiClient.get(`/api/runs/graph`, {
            params: {
                jobId: jobId,
            },
        });
        return resp.data;
    }

    async getRun({ id }) {
        const resp = await dashApiClient.get(`/api/runs/${id}`);
        return resp.data;
    }

    async getRunResult({ runId }) {
        const resp = await dashApiClient.get(`/api/runs/${runId}/results`);
        return resp.data;
    }

    async exportRunResult({ runId, params = {} }) {
        const resp = await dashApiClient.get(`/api/runs/${runId}/export`,
            { timeout: 2 * oneMinute, params });
        return resp.data;
    }

    abortRun(id) {
        return dashApiClient.post(`/api/runs/abort?id=${id}`);
    }

    async getRunResultScenario({ runId, scenarioId }) {
        const resp = await dashApiClient.get(`/api/runs/${runId}/results/${scenarioId}`);
        return resp.data;
    }

    async getQuickStats({ days, byGroup }) {
        const resp = await dashApiClient.get(`/api/runs/stats/summary`, {
            params: {
                days: days,
                byGroup: byGroup,
            },
        });
        return resp.data;
    }

    async getProjectGroupStats({ days }) {
        const resp = await dashApiClient.get(`/api/runs/stats/summary/groups`, {
            params: {
                days: days,
            },
        });
        return resp.data;
    }

    async getProjectStats({ days }) {
        const resp = await dashApiClient.get(`/api/runs/stats/summary/projects`, {
            params: {
                days: days,
            },
        });
        return resp.data;
    }

    async getBugReports(data) {
        const resp = await dashApiClient.get(`/api/runs/stats/bugs`, {
            params: {
                pageNumber: data.pageNumber,
                pageSize: data.pageSize,
                groupId: data.projectGroupId,
                projectId: data.projectId,
                jobId: data.jobId,
                ownerId: data.ownerId,
                fromDate: data.fromDate,
                toDate: data.toDate,
                showAll: data.showAll
            },
            timeout: 2 * oneMinute
        });
        return resp.data;
    }

    async exportBugReports(data) {
        const resp = await dashApiClient.get(`/api/runs/stats/bugs/export`, {
            params: {
                groupId: data.projectGroupId,
                projectId: data.projectId,
                jobId: data.jobId,
                ownerId: data.ownerId,
                fromDate: data.fromDate,
                toDate: data.toDate,
                showAll: data.showAll,
                language: data.language,
            },
            timeout: 2 * oneMinute
        }); 
        return resp.data;
    }
    async getRunReports(data) {
        const resp = await dashApiClient.get(`/api/runs/stats/tester`, {
            params: {
                pageNumber: data.pageNumber,
                pageSize: data.pageSize,
                groupId: data.projectGroupId,
                projectId: data.projectId,
                jobId: data.jobId,
                ownerId: data.ownerId,
                fromDate: data.fromDate,
                toDate: data.toDate,
                showAll: data.showAll
            },
            timeout: 2 * oneMinute
        });
        return resp.data;
    }

    async exportRunReports(data) {
        const resp = await dashApiClient.get(`/api/runs/stats/tester/export`, {
            params: {
                groupId: data.projectGroupId,
                projectId: data.projectId,
                jobId: data.jobId,
                ownerId: data.ownerId,
                fromDate: data.fromDate,
                toDate: data.toDate,
                showAll: data.showAll,
                language: data.language,
            },
            timeout: 2 * oneMinute
        });
        return resp.data;
    }
}

export default new RunApi();
