import { useState, useEffect, useCallback } from "react";

function useLocalStorage(key, initialValue) {
    if (initialValue === void 0) {
        initialValue = "";
    }

    var _useState = useState(function () {
            return window.localStorage.getItem(key) || initialValue;
        }),
        value = _useState[0],
        setValue = _useState[1];

    var setItem = function setItem(newValue) {
        window.localStorage.setItem(key, newValue);
        setValue(newValue);
    };

    useEffect(function () {
        var newValue = window.localStorage.getItem(key);

        if (value !== newValue) {
            setValue(newValue || initialValue);
        }
    });

    var handleStorage = useCallback(
        function (event) {
            if (event.key === key && event.newValue !== value) {
                setValue(event.newValue || initialValue);
            }
        },
        [initialValue, key, setValue, value]
    );

    useEffect(
        function () {
            window.addEventListener("storage", handleStorage);
            return function () {
                return window.removeEventListener("storage", handleStorage);
            };
        },
        [handleStorage]
    );
    return [value, setItem];
}

export default useLocalStorage;
