import React from "react";
import { Pie } from "@ant-design/charts";
import withErrorHandler from "../../util/WithErrorHandler";
import { withTranslation } from "react-i18next";
import { percentage } from "./CommonUtil";
import { Popover } from "antd";
import Text from "antd/lib/typography/Text";

const PieChart = ({ t, data, sKey, fKey, tKey, text, size, uKey = null, displayMode = null }) => {
    const preapreDataForPieChart = () => [
        {
            type: t("Failed"),
            value: getFailValue(),
        },
        {
            type: t("Passed"),
            value: getPassValue(),
        },
        uKey && displayMode?.unstable
            ? {
                  type: t("Unstable"),
                  value: getUnstableValue(),
              }
            : {},
    ];

    const getPassValue = () => {
        if (sKey && fKey) {
            let passCount = data[sKey];
            let unstableCount = uKey ? data[uKey] : 0;
            if (displayMode?.percent) {
                let others = data[fKey];
                if (!displayMode?.unstable) passCount += unstableCount;
                else others += unstableCount;
                return percentage(passCount, others);
            }
            if (!displayMode?.unstable) passCount += unstableCount;
            return passCount;
        }
        return 0;
    };
    const getCount = () => {
        return tKey ? data[tKey] : (sKey && fKey ? data[fKey] + data[sKey] : 0) + (uKey ? data[uKey] : 0);
    };

    const getFailValue = () => {
        if (sKey && fKey) {
            if (displayMode?.percent) {
                let unstableCount = uKey ? data[uKey] : 0;
                let dividend = data[fKey];
                let others = data[sKey] + unstableCount;
                return percentage(dividend, others);
            }
            return data[fKey];
        }
        return 0;
    };

    const getUnstableValue = () => {
        if (sKey && fKey && uKey && data[uKey] !== 0) {
            if (displayMode?.percent) {
                let dividend = data[uKey];
                let others = data[fKey] + data[sKey];
                return percentage(dividend, others);
            }
            return data[uKey];
        }
        return 0;
    };

    var config = {
        data: preapreDataForPieChart(),
        width: size,
        height: size,
        angleField: "value",
        colorField: "type",
        radius: 1,
        legend: false,
        innerRadius: 0.6,
        tooltip: {
            fields: ["x", "y"],
        },
        label: {
            type: "inner",
            offset: "-60%",
            content: displayMode?.percent ? "{value}%" : "{value}",
            autoRotate: !displayMode?.percent,
            rotate: 120,
            style: {
                textAlign: "center",
                fontSize: 12,
            },
        },
        interactions: [{ type: "element-selected" }, { type: "element-active" }],
        statistic: {
            title: {
                offsetY: -4,
                customHtml: (container) => {
                    const { width } = container.getBoundingClientRect();
                    return (
                        <div className="pie-chart-title" style={{ fontSize: width / 6 }}>
                            {t(text ? text : "Scenario")}
                        </div>
                    );
                },
            },
            content: {
                customHtml: (container) => {
                    const { width } = container.getBoundingClientRect();
                    return (
                        <div className="pie-chart-content" style={{ fontSize: width / 4 }}>
                            {getCount()}
                        </div>
                    );
                },
            },
        },
    };
    return (
        <div>
            {displayMode ? (
                <>
                    <Popover
                        placement="left"
                        content={
                            <div>
                                <p>{t("Total Scenarios") + " : " + getCount()} </p>
                                <Text>
                                    {t("Passed") +
                                        " : " +
                                        (displayMode?.unstable ? data[sKey] : data[sKey] + data[uKey])}
                                </Text>
                                <Text>
                                    {",  " +
                                        t("Failed") +
                                        " : " +
                                        (displayMode?.unstable ? data[fKey] + data[uKey] : data[fKey])}
                                </Text>
                                {displayMode?.unstable ? <Text>{",  " + t("Unstable") + " : " + data[uKey]}</Text> : ""}
                            </div>
                        }
                        trigger="hover"
                    >
                        <div style={{ "z-index": 99 }}>
                            <Pie {...config} />
                        </div>
                    </Popover>
                </>
            ) : (
                <Pie {...config} />
            )}
        </div>
    );
};

export default withErrorHandler(
    withTranslation()(
        React.memo(
            PieChart,
            (prevProps, nextProps) =>
                prevProps?.data === nextProps?.data && prevProps.displayMode === nextProps?.displayMode
        )
    )
);
