import { useState, useEffect, useCallback, useRef, useMemo } from "react";
import { openErrorNotification } from "../util/NotificationHandler";

const useApi = ({ api, autoLoad = false, name, params, onSuccess, onError, initialValue } = {}) => {
    const [loaderState, setLoaderState] = useState({ data: initialValue, loading: autoLoad });
    const didCancel = useRef(false);
    const loadingRef = useRef(false);
    const paramRef = useRef({ api, name, onSuccess, onError });

    paramRef.current = { api, name, onSuccess, onError };

    const execute = useCallback((executeParams) => {
        const param = paramRef.current;
        if (loadingRef.current) {
            console.error("Api called again while loading: " + param.name);
        }
        didCancel.current = false;
        loadingRef.current = true;
        setLoaderState((prev) => (prev.loading ? prev : { data: prev.data, loading: true }));
        return param
            .api(executeParams)
            .then((response) => {
                if (didCancel.current) return;
                loadingRef.current = false;
                setLoaderState({ data: response, loading: false });
                param.onSuccess && param.onSuccess(response);
            })
            .catch((err) => {
                if (didCancel.current) return;
                loadingRef.current = false;
                setLoaderState((prev) => ({ data: prev.data, loading: false }));
                if (err?.response?.status === 403) return; // This is handled in axios response interceptor
                if (param.onError && param.onError(err)) return;
                if (err?.response?.status === 404) return; // This is handled in axios response interceptor
                openErrorNotification(
                    `${param.name ? param.name : param.api.name} api error`,
                    err?.response?.data?.message?.trim()
                );
                console.log(err);
            });
    }, []);

    const paramJson = JSON.stringify(params);

    useEffect(() => {
        if (autoLoad) {
            execute(params);
        }
        //Component unMount
        return () => {
            didCancel.current = true;
            setLoaderState((prev) => (prev.loading ? { data: prev.data, loading: false } : prev));
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [autoLoad, paramJson, execute]);

    return useMemo(() => ({ data: loaderState.data, loading: loaderState.loading, execute }), [loaderState, execute]);
};

export { useApi };
