import { Row, Col, PageHeader, Button, Radio, Table, Pagination, DatePicker, Space, Typography, Switch, Form } from "antd";
import React, { useMemo, useEffect, useState, useCallback } from "react";
import { withTranslation } from "react-i18next";
import _ from "lodash";
import uuid from "uuid";
import moment from "moment";
import { useApi } from "../../hook/UseApi";
import ProjectApi from "../../api/dash/ProjectApi";
import ProjectGroupApi from "../../api/dash/ProjectGroupApi";
import JobApi from "../../api/dash/JobApi";
import RunApi from "../../api/dash/RunApi";
import LocalReportApi from "../../api/report/LocalReportApi";
import { getLSLanguage } from "../../util/LocalStorageHandler";
import { createBugColumn, createLocalColumn, createRunColumn } from "./TableColumns";
import useModalControl from "../../hook/UseModalControl";
import RunResultModal from "../common/RunResultModal";
import withErrorHandler from "../../util/WithErrorHandler";
import { downloadFile } from "../../util/Utils";

// TODO : fix -> return empty list from backend if there is no report for selected groups/project/jobs
const ReportTable = ({ t, type, setLoaded }) => {
    const [selectedRun, setSelectedRun] = useState();

    const modalControl = useModalControl({
        onOpen: setSelectedRun,
    });
    const [filterParams, setFilterParams] = useState({
        pageNumber: 1,
        pageSize: 10,
        projectGroupId: null,
        projectId: null,
        jobId: null,
        ownerId: null,
        fromDate: moment().subtract(7,'d').format("YYYY-MM-DD"),
        toDate: moment().add(1, 'd').format("YYYY-MM-DD"),
        showAll: false,
        language: getLSLanguage(),
    });

    const onExportSuccess = (resp) => {
        const fileName = t(type === "bug" ? "Bug Report" : type === "run" ? "Run Report" : "Local Report");
        downloadFile(resp, fileName, fileName + "-" + moment(new Date().getTime()).format("DDMMYYYY-HHmmss"));
    };

    const onReportLoaded = () => {
        setLoaded(true);
    }

    const reportLoader = useApi({
        name: "fetching reports",
        api:
            type === "bug"
                ? RunApi.getBugReports
                : type === "run"
                ? RunApi.getRunReports
                : LocalReportApi.getSavedLocalReports,
        autoLoad: true,
        params: filterParams,
        onSuccess: onReportLoaded,
        onError: onReportLoaded
    });

    const exportReportLoader = useApi({
        name: "export reports",
        api:
            type === "bug"
                ? RunApi.exportBugReports
                : type === "run"
                ? RunApi.exportRunReports
                : LocalReportApi.exportLocalReports,
        onSuccess: onExportSuccess
    });

    const projectGroupLoader = useApi({
        name: "fetching project groups",
        api: ProjectGroupApi.summary,
        initialValue: [],
        autoLoad: true,
    });

    const projectLoader = useApi({
        name: "fetching projects",
        api: ProjectApi.getAll,
        initialValue: [],
        autoLoad: true,
        params: filterParams.projectGroupId,
    });

    const jobsLoader = useApi({
        name: "fetching jobs",
        api: JobApi.getJobInformation,
        autoLoad: true,
        initialValue: [],
        params: filterParams,
    });

    const reports = useMemo(() => {
        if (reportLoader.data && reportLoader.data.pageContent) return reportLoader.data;
        else return { pageCount: 1, pageSize: 10, pageContent: [] };
    }, [reportLoader.data]);

    const jobAndOwnerFilter = useMemo(() => {
        var owners = _.sortBy(_.uniqBy(jobsLoader.data, "ownerId"), "ownerName");
        var jobOwnerFilter = owners.map((owner) => ({
            value: owner.ownerId,
            label: owner.ownerName,
        }));
        var jobFilter = jobsLoader.data.map((job) => ({
            value: job.id,
            label: job.name,
        }));
        return { jobFilter, jobOwnerFilter };
    }, [jobsLoader.data]);

    const projectFilter = useMemo(() => {
        return projectLoader.data.map((project) => ({
            value: project.id,
            label: project.name,
        }));
    }, [projectLoader.data]);

    const filterDatePicker = useCallback(() => {
        return (
            <DatePicker.RangePicker
                value={[filterParams.fromDate && moment(filterParams.fromDate), filterParams.toDate && moment(filterParams.toDate)]}
                format={"YYYY-MM-DD"}
                onChange={(values) =>
                    setFilterParams((prev) => ({
                        ...prev,
                        fromDate: values ? moment(values[0]).format("YYYY-MM-DD") : values,
                        toDate: values ? moment(values[1]).format("YYYY-MM-DD") : values,
                        pageNumber: 1,
                    }))
                }
            />
        );
    }, [filterParams]);

    const filterRadioDropdown = (filterType, options) => {
        if (options[0] && options[0].value !== -1) options.unshift({ value: -1, label: t("All") });
        return (
            <Radio.Group
                key={uuid.v4()}
                defaultValue={-1}
                value={filterParams[filterType] ? filterParams[filterType] : -1}
                className="radio-filter"
                options={options}
                onChange={(e) => {
                    let value = e.target.value !== -1 ? e.target.value : null;
                    setFilterParams((prev) => ({
                        ...prev,
                        projectGroupId: filterType === "projectGroupId" ? value : prev.projectGroupId,
                        projectId:
                            filterType === "projectGroupId"
                                ? null
                                : filterType === "projectId"
                                ? value
                                : prev.projectId,
                        jobId:
                            filterType === "projectGroupId" || filterType === "projectId"
                                ? null
                                : filterType === "jobId"
                                ? value
                                : prev.jobId,
                        ownerId:
                            filterType === "projectGroupId" || filterType === "projectId" || filterType === "jobId"
                                ? null
                                : filterType === "ownerId"
                                ? value
                                : prev.ownerId,
                        fromDate: filterType === "projectGroupId" ? null : prev.fromDate,
                        toDate: filterType === "projectGroupId" ? null : prev.toDate,
                        pageNumber: 1,
                    }));
                }}
            ></Radio.Group>
        );
    };

    const columnFilter = {
        date: filterDatePicker(),
        project: (
            <Space>
                <Space direction="vertical" className="table-filter-centered">
                    <Typography.Text strong>{t("Project Groups")}</Typography.Text>
                    {filterRadioDropdown("projectGroupId", projectGroupLoader.data)}
                </Space>
                <Space className="table-filter-centered" direction="vertical">
                    <Typography.Text strong>{t("Projects")}</Typography.Text>
                    {filterRadioDropdown("projectId", projectFilter)}
                </Space>
            </Space>
        ),
        owner: filterRadioDropdown("ownerId", jobAndOwnerFilter.jobOwnerFilter),
        job: filterRadioDropdown("jobId", jobAndOwnerFilter.jobFilter),
    };

    useEffect(() => {
        setFilterParams((prev) => ({
            ...prev,
            type: type,
            pageNumber: 1,
            projectGroupId: null,
            projectId: null,
            jobId: null,
            ownerId: null,
            fromDate: moment().subtract(7,'d').format("YYYY-MM-DD"),
            toDate: moment().add(1, 'd').format("YYYY-MM-DD"),
            showAll: false
        }));
    }, [type]);

    const onShowAllChange = (checked) => {
        setFilterParams((prev) => ({
            ...prev,
            showAll: checked
        }));
    }

    return (
        <>
            <Row>
                <Col flex={1} className="item-detail-container">
                    <PageHeader
                        className="report-table-header"
                        title={
                            type === "bug"
                                ? t("Bug Reporting")
                                : type === "run"
                                ? t("Run Reporting")
                                : t("Local Reporting")
                        }
                        extra={
                            <>
                            <Form layout="inline">
                                {type === "bug" || type === "run" ? 
                                    <Form.Item label={t("Show all")} valuePropName="showAll" >
                                        <Switch
                                                defaultChecked={false}
                                                onChange={onShowAllChange}
                                                checked={filterParams.showAll}/>
                                    </Form.Item>
                                    : ""
                                }
                                {filterDatePicker()}
                                <Button
                                    key="export"
                                    type="primary"
                                    disabled={!reports.pageContent || reports.pageContent.length < 1}
                                    loading={exportReportLoader.loading}
                                    onClick={() => exportReportLoader.execute(filterParams)}
                                >
                                    {t("Export")}
                                </Button>
                            </Form>
                            </>
                        }
                    />
                    <Table
                        rowKey={() => `${uuid.v4()}`} // unique key for each row
                        onRow={(record) => {
                            return {
                                onClick: (e) =>  {
                                    const ariaLabel = e.target.getAttribute("aria-label");
                                    if (ariaLabel === "Expand") {
                                        e.preventDefault();
                                    }
                                    else {
                                        modalControl.open(record);
                                    }
                                },
                            };
                        }}
                        bordered
                        loading={reportLoader.loading}
                        pagination={{ position: ["none"] }}
                        columns={
                            type === "bug"
                                ? createBugColumn(t, columnFilter)
                                : type === "run"
                                ? createRunColumn(t, columnFilter)
                                : createLocalColumn(t, columnFilter, projectFilter)
                        }
                        dataSource={reports.pageContent}
                        size="small"
                        footer={() => (
                            <Space className="table-footer">
                                <Pagination
                                    key="pagination"
                                    current={filterParams.pageNumber}
                                    responsive
                                    showSizeChanger={false}
                                    onChange={(value) => setFilterParams((prev) => ({ ...prev, pageNumber: value }))}
                                    pageSize={reports.pageSize}
                                    total={reports.pageCount * reports.pageSize}
                                />
                            </Space>
                        )}
                        scroll={{ x: type === "run" || type === "bug" ? 2000 : 880 }}
                    />
                </Col>
            </Row>

            <RunResultModal
                savedLocal={type === "local" ? selectedRun?.username : null}
                runId={type === "local" ? selectedRun?.localRunId : selectedRun?.runId}
                visible={modalControl.modalVisible}
                hideCallback={modalControl.onCancel}
            />
        </>
    );
};

export default withErrorHandler(withTranslation()(ReportTable));
