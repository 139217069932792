import { kuklaApiClient } from "../ApiClient";

class KuklaHealthApi {
    check(timeout) {
        return kuklaApiClient.get("/health", { timeout: timeout });
    }

    register(refreshToken) {
        return kuklaApiClient.post("/register", { "refreshToken": refreshToken });
    }

    stop() {
        return kuklaApiClient.post("/stop");
    }
}

export default new KuklaHealthApi();
