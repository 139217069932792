import React from "react";
import ReactDOM from "react-dom";
import "./translate/i18n";
import App from "./App";
import AppThemeProvider from "./AppThemeProvider";
import { UserProdiver } from "./context/UserContext";
import { BrowserRouter } from "react-router-dom";

ReactDOM.render(
    <UserProdiver>
        <AppThemeProvider>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </AppThemeProvider>
    </UserProdiver>,
    document.getElementById("root")
);
