import { Menu, Image, Dropdown, Typography } from "antd";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useUserContext } from "../../context/UserContext";
import { UserOutlined, SettingOutlined, QuestionOutlined, LogoutOutlined, BugOutlined } from "@ant-design/icons";
import KuklaStatus from "../projectList/KuklaStatus";
import withErrorHandler from "../../util/WithErrorHandler";
import BugReport from "./BugReport";
import useModalControl from "../../hook/UseModalControl";
import { useLocation } from "react-router";
import React, { useMemo } from "react";
import { useThemeSwitcher } from "react-css-theme-switcher";
import { useKuklaContext } from "../../context/KuklaContext";
import KuklaStatusSwitch from "../projectList/KuklaStatusSwitch";
import WorkspaceStatusEnum from "../../api/constant/WorkspaceStatusEnum";
import useLocalStorage from "../../hook/UseLocalStorage";
import KuklaRemoteRestart from "../projectList/KuklaRemoteRestart";

const NavigationMenu = ({ t }) => {
    const { user, updateUser } = useUserContext();
    const { currentTheme } = useThemeSwitcher();
    const { connectedToKukla } = useKuklaContext();
    const { kuklaRemoteEnabled } = useKuklaContext();

    const location = useLocation();
    const projectId = useMemo(() => {
        if (!location) return null;

        let path = location.pathname;
        if (/\/projects\/.*-.*\/.*/.test(path)) return path.split("/")[2].split("-")[0];
        else return null;
    }, [location]);
    const bugReportModalControl = useModalControl({});

    const [workspaceStatus] = useLocalStorage("workspaceStatus", "{}");

    return (
        <>
            <div className="main-nav-bar">
                <div className="logo">
                    <Link to={{ pathname: "/dashboard" }}>
                        <Image src={`/images/${currentTheme}/navbar-logo.png`} preview={false} />
                    </Link>
                </div>

                <div className="menu">
                    <Link to={{ pathname: "/projects" }}>{t("Projects")}</Link>
                    <Link to={{ pathname: "/jobs" }}>{t("Jobs")}</Link>
                    <Link to={{ pathname: "/reports" }}>{t("Reports")}</Link>

                    {user?.roles?.includes("system_admin") && (
                        <div className="icon-container">
                            <Typography.Text>
                                <Link to={{ pathname: "/admin" }}>
                                    <SettingOutlined />
                                </Link>
                            </Typography.Text>
                        </div>
                    )}
                    
                    {kuklaRemoteEnabled && 
                    <>
                        <div className="icon-container">
                            <KuklaStatusSwitch />
                        </div>
                        
                        {workspaceStatus === WorkspaceStatusEnum.REMOTE &&<div className="icon-container restart" >
                            <KuklaRemoteRestart />
                        </div>}
                    </>
                    }

                    <div className="icon-container">
                        <KuklaStatus />
                    </div>
                    <Dropdown
                        overlay={() => {
                            return (
                                <Menu className="top-right-menu">
                                    <Menu.Item key="settings" icon={<SettingOutlined />}>
                                        <Link to={{ pathname: "/settings" }}>{t("Settings")}</Link>
                                    </Menu.Item>
                                    <Menu.Item
                                        key="bugReport"
                                        icon={<BugOutlined />}
                                        disabled={!location.pathname.includes("/projects") || !connectedToKukla}
                                        onClick={bugReportModalControl.open}
                                    >
                                        {t("Bug Report")}
                                    </Menu.Item>
                                    <Menu.Item key="help" icon={<QuestionOutlined />}>
                                        <Link to={{ pathname: "/help" }}>{t("Help")}</Link>
                                    </Menu.Item>
                                    <Menu.Item
                                        key="logout"
                                        data-testid="logout"
                                        icon={<LogoutOutlined />}
                                        onClick={() => updateUser(null)}
                                    >
                                        {t("Logout")}
                                    </Menu.Item>
                                </Menu>
                            );
                        }}
                    >
                        <div className="icon-container">
                            <UserOutlined data-testid="user-menu" />
                        </div>
                    </Dropdown>
                </div>
            </div>

            <BugReport
                visible={bugReportModalControl.modalVisible}
                hideCallback={bugReportModalControl.onCancel}
                projectId={projectId}
            />
        </>
    );
};

export default withErrorHandler(withTranslation()(NavigationMenu));
