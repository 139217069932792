import {
    MinusCircleOutlined,
    ClockCircleOutlined,
    CloseCircleOutlined,
    CheckCircleOutlined,
    ExclamationCircleOutlined,
    SyncOutlined,
} from "@ant-design/icons";
import { Tag } from "antd";

const findRunResult = (data) => {
    let result = "PASSED";
    data.forEach((element) => {
        if (element.resultType === "MISSING REPORT") {
            result = "MISSING REPORT";
        } else if (element.resultType === "RUNNING") {
            return "RUNNING";
        } else if (element.resultType === "FAILED") {
            result = "FAILED";
        } else if (element.resultType === "ABORTED") {
            return "ABORTED";
        }
    });
    return result;
};

export const runDataMapper = (data) => {
    data.results
        .filter((result) => result.resultType === "MISSING_REPORT")
        .forEach((result) => (result.resultType = "MISSING REPORT"));
    const failCount = data.results.filter((result) => result.resultType === "FAILED").length;
    const duration = data.results.reduce((acc, cur) => acc + cur.duration, 0);
    const results = data.results.map((el) => ({ ...el, scenarioStartTime: data.startTime }));
    const saved = data.saved === true;
    return {
        key: data.startTime,
        startTime: data.startTime,
        runResult: {
            state: findRunResult(data.results),
            totalScenarios: data.results.length,
            failScenarios: failCount,
            passScenarios: data.results.length - failCount,
        },
        duration,
        results,
        saved,
        userName: data.results[0].jobId,
    };
};

export const createTag = (t, state) => {
    let icon, color;
    if (state === "PASSED") {
        icon = <CheckCircleOutlined />;
        color = "success";
        state = "COMPLETED";
    } else if (state === "MISSING REPORT") {
        icon = <ExclamationCircleOutlined />;
        color = "magenta";
    } else if (state === "RUNNING") {
        icon = <SyncOutlined spin />;
        color = "gold";
    } else if (state === "FAILED") {
        icon = <CloseCircleOutlined />;
        color = "error";
    } else if (state === "ABORTED") {
        icon = <MinusCircleOutlined />;
        color = "default";
    } else {
        icon = <ClockCircleOutlined />;
        color = "warning";
    }
    return (
        <Tag icon={icon} color={color}>
            {t(state)}
        </Tag>
    );
};
