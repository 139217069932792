import moment from "moment";
import _ from "lodash";
import Paragraph from "antd/lib/typography/Paragraph";

export function createBugColumn(t, filterDropdown) {
    return [
        {
            title: t("Run"),
            dataIndex: "runId",
            align: "center",
            width: 100,
            key: "runId",
            fixed: "left",
        },
        {
            title: t("Date"),
            dataIndex: "failedTime",
            key: "failedTime",
            align: "center",
            width: 150,
            render: (value) => {
                return moment.unix(value).format("DD-MM-YYYY");
            },
        },
        {
            title: t("Owner"),
            dataIndex: "jobOwner",
            key: "jobOwner",
            align: "center",
            width: 200,
            filterDropdown: filterDropdown.owner,
            filterMultiple: false,
        },
        {
            title: t("Job"),
            dataIndex: "jobName",
            key: "jobName",
            align: "center",
            width: 250,
            filterDropdown: filterDropdown.job,
        },
        {
            title: t("Project"),
            dataIndex: "projectName",
            key: "projectName",
            align: "center",
            width: 250,
            filterDropdown: filterDropdown.project,
        },
        {
            title: t("Feature"),
            dataIndex: "featureFile",
            key: "featureFile",
            align: "center",
        },
        {
            title: t("Scenario"),
            dataIndex: "scenarioName",
            key: "scenarioName",
            align: "center",
        },
        {
            title: t("Failed Step"),
            dataIndex: "failedStep",
            key: "failedStep",
            align: "center",
        },
        {
            title: t("Fail Desc."),
            dataIndex: "failDesc",
            key: "failDesc",
            align: "center",
            render: (value) => {
                return (<>
                    <Paragraph
                        ellipsis={{ expandable: true, symbol: 'more', rows: 2, tooltip: value }}
                    >
                        {value}
                    </Paragraph>
                </>);
            },
        },
    ];
}

export function createRunColumn(t, filterDropdown) {
    return [
        {
            title: t("Run"),
            dataIndex: "runId",
            key: "runId",
            width: 100,
            align: "center",
            fixed: "left",
        },
        {
            title: t("Date"),
            dataIndex: "date",
            key: "date",
            align: "center",
            width: 150,
            render: (value) => {
                return moment.unix(value).format("DD-MM-YYYY");
            },
        },
        {
            title: t("Owner"),
            dataIndex: "jobOwner",
            key: "jobOwner",
            align: "center",
            width: 200,
            filterDropdown: filterDropdown.owner,
        },
        {
            title: t("Job"),
            dataIndex: "jobName",
            key: "jobName",
            align: "center",
            width: 250,
            filterDropdown: filterDropdown.job,
        },
        {
            title: t("Project"),
            dataIndex: "projectName",
            key: "projectName",
            align: "center",
            width: 250,
            filterDropdown: filterDropdown.project,
        },
        {
            title: t("Group"),
            dataIndex: "groupName",
            key: "groupName",
            align: "center",
            filterDropdown: filterDropdown.project,
        },
        {
            title: t("Passed Scenario"),
            dataIndex: "passScenarios",
            key: "passScenarios",
            align: "center",
        },
        {
            title: t("Failed Scenario"),
            dataIndex: "failScenarios",
            key: "failScenarios",
            align: "center",
        },
        {
            title: t("Min Passed Duration"),
            dataIndex: "minPassDuration",
            key: "minPassDuration",
            align: "center",
            render: (value) => {
                return moment.utc(moment.duration(value, "milliseconds").asMilliseconds()).format("mm:ss");
            },
        },
        {
            title: t("Min Failed Duration"),
            dataIndex: "minFailDuration",
            key: "minFailDuration",
            align: "center",
            render: (value) => {
                return moment.utc(moment.duration(value, "milliseconds").asMilliseconds()).format("mm:ss");
            },
        },
        {
            title: t("Max Passed Duration"),
            dataIndex: "maxPassDuration",
            key: "maxPassDuration",
            align: "center",
            render: (value) => {
                return moment.utc(moment.duration(value, "milliseconds").asMilliseconds()).format("mm:ss");
            },
        },
        {
            title: t("Max Failed Duration"),
            dataIndex: "maxFailDuration",
            key: "maxFailDuration",
            align: "center",
            render: (value) => {
                return moment.utc(moment.duration(value, "milliseconds").asMilliseconds()).format("mm:ss");
            },
        },
        {
            title: t("Total Passed Duration"),
            dataIndex: "totalPassDuration",
            key: "totalPassDuration",
            align: "center",
            render: (value) => {
                return moment.utc(moment.duration(value, "milliseconds").asMilliseconds()).format("mm:ss");
            },
        },
        {
            title: t("Total Failed Duration"),
            dataIndex: "totalFailDuration",
            key: "totalFailDuration",
            align: "center",
            render: (value) => {
                return moment.utc(moment.duration(value, "milliseconds").asMilliseconds()).format("mm:ss");
            },
        },
    ];
}

export function createLocalColumn(t, filterDropdown, projectFilter) {
    return [
        {
            title: t("Date"),
            dataIndex: "time",
            key: "time",
            align: "center",
            width: 150,
            render: (value) => {
                return moment.unix(value).format("DD-MM-YYYY");
            },
        },
        {
            title: t("Owner"),
            dataIndex: "username",
            key: "username",
            align: "center",
            width: 250,
            filterDropdown: filterDropdown.owner,
        },
        {
            title: t("Project"),
            dataIndex: "projectId",
            key: "projectId",
            align: "center",
            width: 250,
            filterDropdown: filterDropdown.project,
            render: (projectId) => {
                var project = _.find(projectFilter, { value: projectId });
                return project ? project.label : "";
            },
        },
        {
            title: t("Passed Scenario"),
            dataIndex: "passScenarios",
            key: "passScenarios",
            align: "center",
        },
        {
            title: t("Failed Scenario"),
            dataIndex: "failScenarios",
            key: "failScenarios",
            align: "center",
        },
        {
            title: t("Total Duration"),
            dataIndex: "totalDuration",
            key: "totalDuration",
            align: "center",
            render: (value) => {
                return moment.utc(moment.duration(value, "milliseconds").asMilliseconds()).format("mm:ss");
            },
        },
    ];
}
