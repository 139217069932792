import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { getLSLanguage, setLSLanguage } from "../util/LocalStorageHandler";

import TR from "./turkish.json";
import EN from "./english.json";

const browserLanguage = navigator.language;
const langInLocalStorage = getLSLanguage();
const defaultLanguage = langInLocalStorage ? langInLocalStorage : browserLanguage;
setLSLanguage(defaultLanguage === "tr" ? "tr" : "en");

const resources = {
    tr: {
        translation: TR,
    },
    en: {
        translation: EN,
    },
};

i18n.use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: defaultLanguage,
        fallbackLng: "en",

        keySeparator: false, // we do not use keys in form messages.welcome

        interpolation: {
            escapeValue: false, // react already safes from xss
        },
    });

export default i18n;
