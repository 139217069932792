import { Popconfirm } from "antd";
import { withTranslation } from "react-i18next";
import withErrorHandler from "../../util/WithErrorHandler";
import {
    RedoOutlined
  } from '@ant-design/icons';
import { Link } from "react-router-dom";
import KuklaHealthApi from "../../api/kukla/KuklaHealthApi";
import { useKuklaContext } from "../../context/KuklaContext";

const KuklaRemoteRestart = ({ t }) => {
    const { connectedToKukla } = useKuklaContext();

    const restart = () => {
        KuklaHealthApi.stop();
    }

    
    const confirm = (event) => {
        restart();
    }

    const cancel = (event) => {
      //
    }

    return (
        <>
            <Popconfirm
                title={t('Do you want to restart remote Kukla?')}
                description={t("Restart Kukla")}
                onConfirm={confirm}
                onCancel={cancel}
                okText={t('Yes')}
                cancelText={t('No')}
            >
                <Link style={{width:20}} disabled={!connectedToKukla} className={!connectedToKukla ? "restart-disabled": ""} key='restart'>
                    <RedoOutlined />
                </Link>
            </Popconfirm>
        </>
    );
};

export default withErrorHandler(withTranslation()(KuklaRemoteRestart));
