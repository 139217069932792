import { Alert, Button } from "antd";
import React, { Component } from "react";
import { CustomLog } from "./CustomLogHandler";

class ErrorHandler extends Component {
    constructor(props) {
        super(props);
        this.state = { error: null, errorInfo: null };
    }

    componentDidCatch(error, errorInfo) {
        this.setState({
            error: error,
            errorInfo: errorInfo,
        });
        CustomLog.errorTrace(error.name, error.message, errorInfo.componentStack);
    }

    render() {
        if (this.state.errorInfo) {
            return (
                <Alert
                    message="Something went wrong"
                    showIcon
                    description={
                        <details className="error-details">
                            <summary>Click for error details</summary>
                            <pre>{this.state.error.message}</pre>
                            <pre>{this.state.errorInfo.componentStack}</pre>
                        </details>
                    }
                    type="error"
                    action={
                        <Button
                            size="small"
                            onClick={() => {
                                window.location.reload();
                            }}
                        >
                            Reload this page
                        </Button>
                    }
                />
            );
        }
        return this.props.children;
    }
}

const withErrorHandler = (BaseComponent) => (props) =>
    (
        <ErrorHandler>
            <BaseComponent {...props} />
        </ErrorHandler>
    );

export default withErrorHandler;
