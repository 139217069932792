import React, { useEffect, useState } from "react";
import { Trans, withTranslation } from "react-i18next";
import { blue } from "@ant-design/colors";
import { Image, Modal, Typography } from "antd";
import { useThemeSwitcher } from "react-css-theme-switcher";
import withErrorHandler from "../../util/WithErrorHandler";
import HealthApi from "../../api/dash/HealthApi";

const winInstaller = (version) => (
    <a href={`/installer/kukla-win64-installer-${version}.msi`}>
        <Trans i18nKey="agentWindowsVersion">TestB4 Agent {{ version }} Installer for Windows</Trans>
    </a>
);
const macInstaller = (version) => (
    <a href={`/installer/kukla-local-${version}.dmg`}>
        <Trans i18nKey="agentMacVersion">TestB4 Agent {{ version }} Installer for Mac</Trans>
    </a>
);
const jar = (version) => (
    <a href={`/installer/kukla-local-${version}.jar`}>
        <Trans i18nKey="agentJarVersion">TestB4 Agent {{ version }} JAR</Trans>
    </a>
);

const KuklaInstall = ({ t, visible, hideCallback, showHelp }) => {
    const [version, setVersion] = useState();
    const [advancedInstallOpen, setAdvancedInstallOpen] = useState(false);
    const { currentTheme } = useThemeSwitcher();
    const textColor = currentTheme === "dark" ? blue[2] : blue[9];

    useEffect(() => {
        visible && getVersion();
    }, [visible]);

    // endpoint not accessible during develop
    const getVersion = () => {
        HealthApi.version()
            .then((resp) => setVersion(resp.data.kukla))
            .catch((err) => console.debug(err));
    };

    const advanced = (
        <>
            <br />
            <br />
            <Typography className="cursor-pointer" style={{ color: textColor }} onClick={showHelp}>
                {t("Click for Version Upgrade Help")}
            </Typography>
            <Typography
                className="cursor-pointer"
                style={{ color: textColor }}
                onClick={() => setAdvancedInstallOpen(!advancedInstallOpen)}
            >
                {t("Advanced")}
            </Typography>
            <Typography className={advancedInstallOpen ? null : "hidden-class"}>
                {t("If JDK 1.8 is installed")}: java -jar {jar(version)}
            </Typography>
        </>
    );

    const InstallerText = () => {
        if (!version) {
            return <>Installer version not known</>;
        }
        if (window.navigator.platform.includes("Win")) {
            return (
                <>
                    <p>{t("If already installed, run TestB4 agent using the shortcut on your desktop")}</p>
                    <p>
                        {t("To install for Windows")}: {winInstaller(version)}
                    </p>
                    {advanced}
                </>
            );
        } else if (window.navigator.platform.includes("Mac")) {
            return (
                <>
                    <p>{t("If already installed, run TestB4 agent")}</p>
                    <p>
                        {t("To install for Mac")}: {macInstaller(version)}
                    </p>
                    {advanced}
                </>
            );
        }
        return (
            <>
                <p>
                    {t("If you have JDK 1.8 installed, you may use")}: {jar(version)}
                </p>
            </>
        );
    };

    return (
        <Modal visible={visible} onCancel={hideCallback} footer={null} destroyOnClose>
            <div className="kukla-modal-container">
                <Image src={`/images/${currentTheme}/puppet.png`} preview={false} />

                <p>{t("Please run TestB4 - Agent")}</p>
                <InstallerText />
            </div>
        </Modal>
    );
};

export default withErrorHandler(withTranslation()(KuklaInstall));
