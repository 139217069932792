import React from "react";
import { Helmet } from "react-helmet";

const BrowserTabTitle = () => {
    const title = window.location.host.match(/snapbytes|snaptest|testb4|localhost/) ? "TestB4" : "VisiumGO";

    return (
        <Helmet>
            <title>{title}</title>
        </Helmet>
    );
};

export default BrowserTabTitle;
