import React, { useCallback, useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import KuklaHealthApi from "../../api/kukla/KuklaHealthApi";
import { CheckCircleOutlined, ClockCircleOutlined, StopOutlined, DisconnectOutlined } from "@ant-design/icons";
import { useKuklaContext } from "../../context/KuklaContext";
import { Button, Popover, Typography } from "antd";
import { useUserContext } from "../../context/UserContext";
import { openErrorNotification, openWarningNotification } from "../../util/NotificationHandler";
import KuklaInstall from "./KuklaInstall";
import useModalControl from "../../hook/UseModalControl";
import withErrorHandler from "../../util/WithErrorHandler";
import HealthApi from "../../api/dash/HealthApi";
import { showInTooltip } from "../../util/TooltipHandler";
import KuklaStatusApi from "../../api/kukla/KuklaStatusApi";
import StateEnum from "../../api/constant/StateEnum";
import OperatorApi from "../../api/dash/OperatorApi";
import { useApi } from "../../hook/UseApi";
import WorkspaceStatusEnum from "../../api/constant/WorkspaceStatusEnum";
import useLocalStorage from "../../hook/UseLocalStorage";

// TODO icons are just placeholders, replace them with animations
const screenText = {
    INSTALL: {
        title: "Not Connected",
        icon: <DisconnectOutlined data-testid="Not Connected" className="jello-animate" />,
    },
    INITIALIZING: {
        title: "Initializing",
        icon: <ClockCircleOutlined data-testid="Initializing" className="jello-animate-no-iterate" spin />,
    },
    READY: {
        title: "Registering",
        icon: <ClockCircleOutlined data-testid="Registering" className="jello-animate" />,
    },
    USER_NOT_FOUND: {
        title: "Failed",
        icon: <StopOutlined data-testid="Failed" />,
    },
    LOADING_PROJECTS: {
        title: "Loading projects",
        icon: <ClockCircleOutlined data-testid="Loading projects" className="jello-animate" />,
    },
    INIT_FAILED: {
        title: "Failed",
        icon: <StopOutlined data-testid="Failed" className="jello-animate" />,
    },
    LICENSE_EXPIRED: {
        title: "License Expired",
        icon: <StopOutlined data-testid="License Expired" className="jello-animate" />,
    },
    UP: {
        title: "Connected",
        icon: <CheckCircleOutlined data-testid="Connected" className="jello-animate" />,
    },
};

const KuklaStatus = ({ t }) => {
    const { user, updateUser } = useUserContext();
    const { connectedToKukla, updateKuklaConnection, setKuklaRemoteEnabled } = useKuklaContext();
    const [status, setStatus] = useState(StateEnum.INSTALL);
    const [showHelp, setShowHelp] = useState(false);
    const [workspaceStatus, setWorkspaceStatus] = useLocalStorage("workspaceStatus", "0");

    const installModalControl = useModalControl();

    const checkKuklaVersion = useCallback((currentVersion) => {
        if (currentVersion) {
            HealthApi.version()
                .then((resp) => {
                    const availableVersion = resp.data.kukla;
                    if (availableVersion && currentVersion !== availableVersion) {
                        openWarningNotification(
                            "New version available, stopping kukla.",
                            `Please upgrade to Kukla ${availableVersion}`,
                            30
                        );
                        KuklaHealthApi.stop();
                        setShowHelp(true);
                    }
                })
                .catch((err) => console.debug(err));
        }
    }, []);

    const healthCheck = useCallback(
        (timeout) => {
            KuklaStatusApi.retrieve(timeout)
                .then((resp) => {
                    const statusDTO = resp.data.data;

                    const state = statusDTO.state;
                    const version = statusDTO.version;

                    setStatus(state);

                    if (state === StateEnum.READY) {
                        KuklaHealthApi.register(user.refresh_token);
                        updateKuklaConnection(false);
                        return;
                    }
                    else if (state === StateEnum.USER_NOT_FOUND) {
                        openErrorNotification("Kukla authorization error", "User not found");
                        updateUser(null); // logout
                        updateKuklaConnection(false);
                        return;
                    }
                    else if (state === StateEnum.UP) {
                        // TODO We should move this check to kukla-local
                        const diskspaceDetails = statusDTO?.details?.diskSpace?.details;
                        if (diskspaceDetails?.free < diskspaceDetails?.threshold) {
                            openWarningNotification("Running out of local disk space!");
                        }
                        checkKuklaVersion(version);
                        updateKuklaConnection(true);
                        return;
                    }
                    
                    updateKuklaConnection(false);
                })
                .catch((err) => {
                    console.error(err);
                    updateKuklaConnection(false);
                    setStatus(StateEnum.INSTALL);
                });
        },
        [checkKuklaVersion, updateKuklaConnection, updateUser, user.refresh_token]
    );

    const onOperatorStatusError = (err) => {
        setKuklaRemoteEnabled(false);
        setWorkspaceStatus(WorkspaceStatusEnum.LOCAL);
    };

    const onOperatorStatusSuccess = (resp) => {
        if (resp?.data?.success) {
            setKuklaRemoteEnabled(true);
        }
        else {
            console.debug("workspace status: " + workspaceStatus);
            setKuklaRemoteEnabled(false);
            setWorkspaceStatus(WorkspaceStatusEnum.LOCAL);
        }
        
    }

    useApi({
        name: "fetching kukla operator status",
        api: OperatorApi.status,
        onSuccess: (resp) => onOperatorStatusSuccess(resp),
        onError: onOperatorStatusError,
        params: {timeout: 5000},
        autoLoad: true
    });

    useEffect(() => {
        let delay = 10000;
        if (connectedToKukla) {
            delay = 10000;
        } else if (installModalControl.modalVisible || status !== StateEnum.INSTALL) {
            delay = 2000;
        } else {
            healthCheck(delay - 100);
        }
        const timerId = setInterval(healthCheck, delay);
        return function cleanup() {
            clearInterval(timerId);
        };
        
    }, [connectedToKukla, healthCheck, installModalControl.modalVisible, status]);

    const onClick = () => {
        status === StateEnum.INSTALL && installModalControl.open();
    };
    useEffect(() => {
        installModalControl.modalVisible && status !== StateEnum.INSTALL && installModalControl.onCancel();
    }, [installModalControl, status]);

    const className = status === StateEnum.INSTALL ? "kukla-status install" : "kukla-status";

    const content = (
        <>
            <Typography>
                {t("1 - Make sure the old version is not running.")} {screenText.INSTALL.icon} :{" "}
                {t("This icon means Kukla is not running")}
            </Typography>
            <Typography>
                {t("2 - Open the Kukla Install Modal by clicking here or the kukla connection icon.")}
            </Typography>
            <Typography>{t("3 - Download the setup file from the given download link.")}</Typography>
            <Typography>{t("4 - Run the setup file and install it by following the steps.")}</Typography>
            <Typography>
                {t(
                    "5 - In case you encounter any errors, uninstall the old version from your computer and continue from step 4."
                )}
            </Typography>
            <Typography>
                {t(
                    "6 - After the installation is complete, you can continue your work from where you left off by running the Kukla."
                )}
            </Typography>
            <br />
            <Typography>
                {t("Help in TestB4 App")} : {t("Kukla Install Modal -> Version Upgrade Help")}
            </Typography>
            <Typography>
                {t("Help in Documentation")} : {t("User Menu -> Help -> Beginning -> Kukla Application")}
            </Typography>
            <br />
            <Button
                type="primary"
                onClick={(e) => {
                    setShowHelp(false);
                    e.stopPropagation();
                }}
            >
                {t("Close")}
            </Button>
        </>
    );

    return (
        <>
            <Typography.Text className={className} onClick={onClick}>
                <Popover
                    placement="leftTop"
                    content={content}
                    title={t("Follow the steps below to upgrade the Kukla!")}
                    visible={showHelp}
                >
                    {showInTooltip("kukla-status", "Kukla : " + t(screenText[
                        Object.entries(StateEnum).filter(([k,v]) => v === status).map(([k,v])=> 
                        {console.log("found k:" + k); return k;})[0]

                    ]?.title), screenText[
                        Object.entries(StateEnum).filter(([k,v]) => v === status).map(([k,v])=> k)[0]
                    ]?.icon)
                        
                    }
                </Popover>
                
            </Typography.Text>
           
            <KuklaInstall
                visible={installModalControl.modalVisible}
                hideCallback={installModalControl.onCancel}
                showHelp={() => setShowHelp(true)}
            />
        </>
    );
};

export default withErrorHandler(withTranslation()(KuklaStatus));