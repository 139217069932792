import React, { useEffect, useMemo, useState } from "react";
import { Alert, Button, Input, Select, Image, Form } from "antd";
import { UserOutlined, LockOutlined, CloudServerOutlined } from "@ant-design/icons";
import { useUserContext } from "../../context/UserContext";
import { withTranslation } from "react-i18next";
import LdapApi from "../../api/dash/LdapApi";
import LoginApi from "../../api/dash/LoginApi";
import { useApi } from "../../hook/UseApi";
import withErrorHandler from "../../util/WithErrorHandler";

const LoginPage = ({ t, theme, history }) => {
    const { updateUser } = useUserContext();
    const [form] = Form.useForm();
    const [alertMessage, setAlertMessage] = useState(null);

    const ldapLoader = useApi({
        name: "fetching ldap servers",
        api: LdapApi.summary,
        initialValue: [],
        autoLoad: true,
    });

    const ldapOptions = useMemo(() => {
        return ldapLoader.data.map((ldapServer) => ({
            value: ldapServer.id,
            label: (
                <span className="ldap-dropdown-element">
                    <CloudServerOutlined />
                    {" " + ldapServer.name}
                </span>
            ),
        }));
    }, [ldapLoader.data]);

    const onSuccess = (resp) => {
        updateUser(resp.data);
        history.push("/dashboard");
    };

    const onError = (err) => {
        if (err?.response?.data?.message) setAlertMessage(err.response.data.message);
        else if (err?.response?.statusText) setAlertMessage(err.response.statusText);
        else setAlertMessage(t("Login failed!"));
        return true;
    };

    const login = useApi({
        name: "logging in",
        onSuccess: onSuccess,
        onError: onError,
        api: LoginApi.login,
    });

    const onFinish = () => {
        form.validateFields().then(login.execute);
    };

    useEffect(() => {
        ldapOptions?.length > 0 && form.setFieldsValue({ ldapId: ldapOptions[0].value });
    }, [form, ldapOptions]);

    return (
        <Form form={form} name="login" className="centered-wrapper" onFinish={onFinish}>
            <Form.Item>
                <Image src={`/images/${theme}/login-logo.png`} className="big-logo" preview={false} />
            </Form.Item>
            <Form.Item
                size="large"
                name="username"
                rules={[{ required: true, pattern: "^[^ ].+[^ ]$", message: t("Please enter your Username!") }]}
            >
                <Input prefix={<UserOutlined />} size="large" placeholder={t("Username")} />
            </Form.Item>
            <Form.Item
                size="large"
                name="password"
                rules={[{ required: true, pattern: "^[^ ].+[^ ]$", message: t("Please enter your Password!") }]}
            >
                <Input.Password prefix={<LockOutlined />} size="large" type="password" placeholder={t("Password")} />
            </Form.Item>
            <Form.Item
                name="ldapId"
                className="input-row"
                rules={[{ required: true, message: t("Please select an ldap server!") }]}
            >
                <Select
                    options={ldapOptions}
                    loading={ldapLoader.loading}
                    placeholder={t("Select LDAP Server")}
                    size="large"
                />
            </Form.Item>
            {alertMessage && (
                <Form.Item name="alert" className="input-row">
                    <Alert
                        className="ldap-dropdown"
                        message={alertMessage}
                        type="error"
                        closable
                        onClose={() => setAlertMessage("")}
                    />
                </Form.Item>
            )}
            <Form.Item className="input-row">
                <Button className="login-button" size="large" htmlType="submit" type="primary" loading={login.loading}>
                    {t("Login")}
                </Button>
            </Form.Item>
        </Form>
    );
};

export default withErrorHandler(withTranslation()(LoginPage));
