import axios from "axios";
import { useEffect, useCallback } from "react";
import HealthApi from "./api/dash/HealthApi";
import LoginApi from "./api/dash/LoginApi";
import { useUserContext } from "./context/UserContext";
import { CustomLog } from "./util/CustomLogHandler";
import { openErrorNotification } from "./util/NotificationHandler";

const DashTimers = () => {
    const { user, updateUser } = useUserContext();
    const healthDelay = 12000;
    const refreshDelay = 100000;

    const healthCheck = useCallback(() => {
        HealthApi.check()
            .then(() => {
                console.debug("Health check triggered: Success");
            })
            .catch((err) => {
                console.log("Health check triggered: Fail", err);
                if (err.message !== "Network Error" && !axios.isCancel(err) && err.code !== "ECONNABORTED") {
                    CustomLog.error("health check error logout", err);
                    updateUser(null); //logout
                } else {
                    CustomLog.error("health check timeout", err);
                }
            });
    }, [updateUser]);

    useEffect(() => {
        healthCheck();
        const healthTimer = setInterval(healthCheck, healthDelay);
        const refreshTimer = setInterval(() => {
            const nextExpire = new Date().getTime() + user.expires_in * 1000;
            if (user.expires_at && user.expires_at > nextExpire - refreshDelay) {
                console.log("Another tab is open. Skip refresh");
                return;
            }
            LoginApi.refresh(user.refresh_token, user.git_token, user.git_token_refresh_at, user.user_token)
                .then((resp) => {
                    let user = resp.data;
                    user.expires_at = nextExpire;
                    updateUser(user);
                    console.debug("Access token refresh: Success");
                })
                .catch((err) => {
                    if (err.message !== "Network Error" && !axios.isCancel(err) && err.code !== "ECONNABORTED") {
                        CustomLog.error("refresh error logout", err);
                        openErrorNotification("Session timed out, please login again");
                        updateUser(null); //logout
                    } else {
                        CustomLog.error("refresh timeout", err);
                    }
                });
        }, refreshDelay);
        return function cleanup() {
            clearInterval(healthTimer);
            clearInterval(refreshTimer);
        };
    }, [
        updateUser,
        user.refresh_token,
        user.git_token,
        user.git_token_refresh_at,
        user.user_token,
        user.expires_in,
        user.expires_at,
        healthCheck,
        healthDelay,
        refreshDelay,
    ]);

    return null;
};

export default DashTimers;
