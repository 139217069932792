import axios from "axios";
import { openErrorNotification } from "../util/NotificationHandler";
import WorkspaceStatusEnum from "./constant/WorkspaceStatusEnum"

// use this variable instead of timeout=0 (unlimited)
export const maxTimeout = 600000; // 10 mins
export const oneMinute = 60000; // 1 min

let user = null;

export const dashUrl = process.env.REACT_APP_DASH_URL ? process.env.REACT_APP_DASH_URL : window.location.origin;
export const kuklaUrl = process.env.REACT_APP_KUKLA_URL ? process.env.REACT_APP_KUKLA_URL : "http://localhost:4080";
export const operatorUrl = process.env.REACT_APP_OPERATOR_PROXY_URL ? process.env.REACT_APP_OPERATOR_PROXY_URL : dashUrl + "/operator";
export const reportUrl = process.env.REACT_APP_REPORT_URL ? process.env.REACT_APP_REPORT_URL : window.location.origin;

const workspaceStatus = window.localStorage.getItem("workspaceStatus");
console.log("workspaceStatus: " + workspaceStatus);

function createAxiosClient(baseURL) {
    const client = axios.create({
        baseURL: baseURL,
        timeout: 10000
    });

    client.interceptors.request.use(
        async (config) => {
            // TODO: test multiple tabs
            if (user && !config.url?.startsWith("/versions.json")) {
                config.headers["Authorization"] = "Bearer " + user.access_token;
                config.headers["X-TestB4-Url"] = dashUrl;
            }
            return config;
        },
        (err) => {
            return Promise.reject(err);
        }
    );

    client.interceptors.response.use(
        (resp) => resp,
        (err) => {
            if (err?.response?.status === 403) {
                // auth
                openErrorNotification("Permission denied!");
                console.log(err);
            } else if (err?.response?.status === 404) {
                // not found
                console.log(err);
            }
            throw err; // must be here
        }
    );

    return client;
}

export const setApiUser = (newUser) => {
    user = newUser;
};

export const dashApiClient = createAxiosClient(dashUrl);
export const kuklaApiClient = createAxiosClient(!workspaceStatus || workspaceStatus === WorkspaceStatusEnum.LOCAL ? kuklaUrl : operatorUrl);
export const reportApiClient = createAxiosClient(reportUrl);
