import { dashApiClient } from "../ApiClient";
import uuid from "uuid";

class HealthApi {
    check() {
        return dashApiClient.get(`/api/status`);
    }

    version() {
        return dashApiClient.get(`/versions.json?` + uuid.v4());
    }
}

export default new HealthApi();
