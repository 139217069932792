import { dashApiClient } from "../ApiClient";

class LoginApi {
    login({ username, password, ldapId }) {
        return dashApiClient.post(
            "/login",
            {
                username: "REQUIRED_NOT_BLANK",
                password: "REQUIRED_NOT_BLANK",
                encoded: Buffer.from(username + ":" + password).toString("base64"),
                ldapId: ldapId,
            },
            { timeout: 30000 }
        );
    }

    refresh(refreshToken, gitToken, gitTokenRefreshAt, userToken) {
        return dashApiClient.post("/login/refresh", {
            grant_type: "refresh_token",
            refresh_token: refreshToken,
            git_token: gitToken,
            git_token_refresh_at: gitTokenRefreshAt,
            user_token: userToken
        });
    }
}

export default new LoginApi();
