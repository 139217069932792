const StateEnum = {
    INSTALL: -1,
    NOT_LOGGED_IN: 1,
    INITIALIZING: 2,
    READY: 3,
    LOADING_PROJECTS:4,
    INIT_FAILED: 5,
    USER_NOT_FOUND: 6,
    LICENSE_EXPIRED: 7,
    UP: 8
}
export default StateEnum;