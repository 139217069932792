import { dashApiClient } from "../ApiClient";

class LdapApi {
    async summary() {
        const resp = await dashApiClient.get("/api/ldap/summary");
        return resp.data;
    }

    async getLdapServerList() {
        const resp = await dashApiClient.get(`/api/ldap`);
        return resp?.data;
    }

    async hasInternalServer() {
        const resp = await dashApiClient.get(`/api/ldap/local/hasInternal`);
        return resp?.data;
    }

    async getLocalUserList() {
        const resp = await dashApiClient.get(`/api/ldap/local`);
        return resp?.data.map((user, index) => ({
            id: index,
            name: user,
        }));
    }

    async saveUser({ ldapServer, username }) {
        return dashApiClient.post(`/api/ldap/user`, {
            username: username,
            ldapId: ldapServer,
        });
    }

    async findUsers({ ldapServer, search }) {
        const resp = await dashApiClient.get(`/api/ldap/user/${ldapServer}/${search}`);
        return resp.data.map((user) => ({
            label: user,
            value: user,
        }));
    }

    createLocalUser(data) {
        return dashApiClient.post(`/api/ldap/local/user/add`, data);
    }

    resetPassLocalUser({ username, password }) {
        return dashApiClient.put(`/api/ldap/local/user/${username}/reset`, { password });
    }

    resetOwnPassLocalUser({ username, oldPassword, password }) {
        return dashApiClient.put(`/api/ldap/local/user/user/${username}/reset`, { oldPassword, password });
    }

    updateLocalUser(data) {
        return dashApiClient.put(`/api/ldap/local/user/${data.username}/update`, {
            name: data.name,
            surname: data.surname,
            displayName: data.displayName,
        });
    }

    deleteLocalUser(username) {
        return dashApiClient.delete(`/api/ldap/local/user/${username}/delete`);
    }

    async getLocalLdapUser(username) {
        const resp = await dashApiClient.get(`/api/ldap/local/user/${username}`);
        return resp.data;
    }

    async getLdapServerDefault(type) {
        const resp = await dashApiClient.get(`/api/ldap/default/${type}`);
        return resp.data;
    }

    createLdapServer(data) {
        return dashApiClient.post(`/api/ldap`, data);
    }

    testLdapServer(data) {
        return dashApiClient.post(`/api/ldap/connection`, data);
    }

    updateLdapServer(data) {
        return dashApiClient.put(`/api/ldap`, data);
    }

    deleteLdapServer(id) {
        return dashApiClient.delete(`/api/ldap/${id}`);
    }
}

export default new LdapApi();
