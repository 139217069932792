import { Row, Col, Radio, Input, Card, List, Popover, Select } from "antd";
import { PageHeader, Statistic, Descriptions, Progress } from "antd";
import { Content } from "antd/lib/layout/layout";
import _ from "lodash";
import React, { useMemo, useState } from "react";
import { withTranslation } from "react-i18next";
import RunApi from "../../api/dash/RunApi";
import { useApi } from "../../hook/UseApi";
import moment from "moment";
import PieChart from "../common/PieChart";
import withErrorHandler from "../../util/WithErrorHandler";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Trans } from "react-i18next";
import ProjectApi from "../../api/dash/ProjectApi";
import { percentage } from "../common/CommonUtil";

const options = [
    {
        label: <Trans>7 days</Trans>,
        value: "7",
    },
    {
        label: <Trans>15 days</Trans>,
        value: "15",
    },
    {
        label: <Trans>30 days</Trans>,
        value: "30",
    },
];

const DashboardPage = ({ t }) => {
    const baseOption = [
        { label: t("Group"), value: "group" },
        { label: t("Project"), value: "project" },
    ];
    const [search, setSearch] = useState("");
    const [teamId, setTeamId] = useState(undefined);
    const [days, setNDay] = useState(options[0].value);
    const [base, setBase] = useState(baseOption[0].value);
    const [selectedId, setSelectedId] = useState(null);

    // TODO : Remove base , api should reload when url changes
    const statsLoader = useApi({
        name: base === "project" ? "fetching project stats" : "fetching groups stats",
        api: base === "project" ? RunApi.getProjectStats : RunApi.getProjectGroupStats,
        autoLoad: true,
        params: { days: days, base },
    });

    const quickStatsLoader = useApi({
        name: "fetching project group stats",
        api: RunApi.getQuickStats,
        autoLoad: true,
        params: { days: days, byGroup: base === "group" },
    });

    const teamsLoader = useApi({
        name: "fetching project teams",
        api: ProjectApi.getTeams,
        autoLoad: true,
    });

    const activeStats = useMemo(
        () => _.filter(statsLoader.data, (item) => item.successScenarioCount + item.failedScenarioCount > 0),
        [statsLoader.data]
    );

    const dataSource = useMemo(() => {
        var filteredData = _.filter(activeStats, (item) => _.lowerCase(item.name).includes(_.lowerCase(search)));
        if (teamId !== undefined && base === "group") {
            filteredData = _.filter(filteredData, (item) => item.team?.id === teamId);
        }
        return _.orderBy(
            filteredData,
            (group) => percentage(group.successScenarioCount, group.failedScenarioCount),
            "desc"
        );
    }, [activeStats, teamId, base, search]);

    const groupCount = activeStats &&
        statsLoader.data &&
        quickStatsLoader.data && {
            // active field = total when base==group.
            active: base === "group" ? activeStats.length : statsLoader.data.length,
            passive:
                base === "group"
                    ? quickStatsLoader.data.passiveGroupsCount + (statsLoader.data.length - activeStats.length)
                    : quickStatsLoader.data.passiveGroupsCount - statsLoader.data.length,
        };

    const jobPercentage = useMemo(() => {
        let runList = [];
        if (!dataSource && !selectedId) return runList;
        if (selectedId) {
            let groupsRuns = _.find(dataSource, { id: selectedId });
            groupsRuns &&
                groupsRuns.lastRun &&
                _.filter(groupsRuns.lastRun, (run) => {
                    if (!_.find(runList, { id: run.id })) {
                        runList.push(run);
                    }
                });
        } else {
            _.filter(dataSource, (group) =>
                _.filter(group.lastRun, (run) => {
                    if (!_.find(runList, { id: run.id })) {
                        runList.push(run);
                    }
                })
            );
        }
        // TODO : return startTime as long from backend
        const filteredRuns = _.filter(runList, (run) =>
            moment([
                run.startTime[0],
                run.startTime[1] - 1,
                run.startTime[2],
                run.startTime[3],
                run.startTime[4],
                run.startTime[5],
            ]).isSameOrAfter(moment().subtract(days, "days").startOf("day"))
        );
        return _.orderBy(
            filteredRuns,
            (job) => percentage(job.runResult.passScenarios, job.runResult.failScenarios),
            "desc"
        );
    }, [dataSource, days, selectedId]);

    const teamSelection = (
        <Select
            showSearch
            size="large"
            className="filter-select-item"
            allowClear
            placeholder={t("Project Team")}
            value={teamId}
            options={teamsLoader.data}
            loading={teamsLoader.loading}
            onChange={setTeamId}
            filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        />
    );
    const nDaysButtons = (
        <Radio.Group
            options={options}
            defaultValue={days}
            onChange={(e) => setNDay(e.target.value)}
            optionType="button"
        />
    );

    const baseButtons = (
        <Radio.Group
            key="base"
            defaultValue={base}
            options={baseOption}
            onChange={(e) => setBase(e.target.value)}
            optionType="button"
        />
    );

    const popoverContent = (item) => {
        let newestTime = _.min(item.lastRun ? item.lastRun : [], (el) => {
            return new Date(el.startTime).getTime();
        })?.startTime;

        return (
            <Descriptions size="small" bordered column={1}>
                <Descriptions.Item label={t("Group Name")}>{item.name}</Descriptions.Item>
                <Descriptions.Item label={t("Owner")}>{item.owner}</Descriptions.Item>
                <Descriptions.Item label={t("Starting Date")}>
                    {item.createdAt && moment.unix(item.createdAt).format("DD.MM.YYYY")}
                </Descriptions.Item>
                <Descriptions.Item label={t("Update Date")}>
                    {item.updatedAt && moment(item.updatedAt).format("DD.MM.YYYY")}
                </Descriptions.Item>
                <Descriptions.Item label={t("Last Run")}>
                    {newestTime ? moment([newestTime[0], newestTime[1] - 1, newestTime[2]]).format("DD.MM.YYYY") : ""}
                </Descriptions.Item>
                <Descriptions.Item label={t("Rate")}>
                    {percentage(item.successScenarioCount, item.failedScenarioCount)}
                </Descriptions.Item>
                <Descriptions.Item label={t("Scenario Coverage") + "+"}>
                    {percentage(item.successScenarioCount, item.failedScenarioCount)}
                </Descriptions.Item>
                <Descriptions.Item label={t("Scenario Coverage") + "-"}>
                    {percentage(item.failedScenarioCount, item.successScenarioCount)}
                </Descriptions.Item>
                <Descriptions.Item label={t("Failed Scenario")}>{item.failedScenarioCount}</Descriptions.Item>
                <Descriptions.Item label={t("Success Scenario")}>{item.successScenarioCount}</Descriptions.Item>
                <Descriptions.Item label={t("Total Scenario")}>
                    {item.failedScenarioCount + item.successScenarioCount}
                </Descriptions.Item>
            </Descriptions>
        );
    };

    const onSearchChange = useMemo(() => {
        return _.debounce((e) => setSearch(e.target.value), 500);
    }, []);

    return (
        <>
            <Content className="padded-content-left">
                <Row className="full-height">
                    <Col className="left-side-container">
                        <div className="flex-justify-between left-side-header">
                            <PageHeader title={t("Dashboard")} />
                        </div>
                        <div className="left-filter-container">
                            <div>
                                <h2>{t("Search")}</h2>
                                <Input
                                    size="large"
                                    allowClear
                                    disabled={false}
                                    placeholder={t("Search")}
                                    onChange={onSearchChange}
                                />
                            </div>
                            {teamsLoader.data?.length > -1 && (
                                <div>
                                    <h2>{t("Filter by team")}</h2>
                                    {teamSelection}
                                </div>
                            )}
                            <div>
                                <h2>{t("Analyse each")}</h2>
                                {baseButtons}
                            </div>
                            <div>
                                <h2>{t("Analyse last N days")}</h2>
                                {nDaysButtons}
                            </div>
                        </div>
                    </Col>
                    <Col className="right-content-container-dashboard">
                        <Row wrap={false}>
                            <Col flex={1}>
                                <Row
                                    gutter={16}
                                    data-testid="active-group-count"
                                    className={"dashboard-statistic-container"}
                                >
                                    <Col span={6}>
                                        <Card className="card-stat-dash">
                                            <Statistic
                                                title={base === "project" ? t("Active Projects") : t("Active Groups")}
                                                value={groupCount?.active}
                                            />
                                        </Card>
                                    </Col>
                                    <Col span={6}>
                                        <Card className="card-stat-dash">
                                            <Statistic
                                                title={
                                                    base === "project" ? t("Inactive Projects") : t("Inactive Groups")
                                                }
                                                value={groupCount?.passive}
                                            />
                                        </Card>
                                    </Col>
                                    <Col span={6}>
                                        <Card className="card-stat-dash">
                                            <Statistic
                                                title={t("Active Jobs")}
                                                value={quickStatsLoader.data && quickStatsLoader.data.activeJobs}
                                            />
                                        </Card>
                                    </Col>
                                    <Col span={6}>
                                        <Card className="card-stat-dash">
                                            <Statistic
                                                title={t("Inactive Jobs")}
                                                value={quickStatsLoader.data && quickStatsLoader.data.passiveJobs}
                                            />
                                        </Card>
                                    </Col>
                                </Row>

                                <List
                                    className="dashboard-chart-list"
                                    grid={{ gutter: 16, column: 4, xs: 1, sm: 1, md: 2, lg: 2 }}
                                    itemLayout="vertical"
                                    dataSource={dataSource}
                                    renderItem={(item) => (
                                        <List.Item
                                            key={item.id}
                                            onClick={() => {
                                                selectedId === item.id ? setSelectedId(null) : setSelectedId(item.id);
                                            }}
                                        >
                                            <Card
                                                size="small"
                                                className={
                                                    (selectedId === item.id ? "card-selected" : "") +
                                                    " card-dash cursor-pointer"
                                                }
                                                title={item.name}
                                                bordered={false}
                                                extra={
                                                    <Popover
                                                        className="dash-popover"
                                                        placement="rightTop"
                                                        content={popoverContent(item)}
                                                    >
                                                        <InfoCircleOutlined data-testid="info-button" />
                                                    </Popover>
                                                }
                                            >
                                                <PieChart
                                                    data={item}
                                                    sKey="successScenarioCount"
                                                    fKey="failedScenarioCount"
                                                    size={250}
                                                />
                                            </Card>
                                        </List.Item>
                                    )}
                                />
                            </Col>
                        </Row>
                    </Col>
                    <Col className="dashboard-right-container">
                        <PageHeader className="padding-reset-x job-percentage-header" title={t("Job Percentage")} />
                        {jobPercentage.length > 0 && (
                            <>
                                {jobPercentage.map((item) => {
                                    return (
                                        <div key={item.job.name} className="dashboard-progress-container">
                                            <h5>{item.job.name}</h5>
                                            <Progress
                                                percent={percentage(
                                                    item.runResult.passScenarios,
                                                    item.runResult.failScenarios
                                                )}
                                                strokeWidth={10}
                                            />
                                        </div>
                                    );
                                })}
                            </>
                        )}
                    </Col>
                </Row>
            </Content>
        </>
    );
};

export default withErrorHandler(withTranslation()(DashboardPage));
