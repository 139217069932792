import { notification } from "antd";
import i18n from "../translate/i18n";

const openSuccessNotification = (message, description, seconds = 2) => {
    notification["success"]({
        message: i18n.t(message),
        description: i18n.t(description),
        duration: seconds,
    });
};

const openWarningNotification = (message, description, seconds = 2) => {
    notification["warning"]({
        message: i18n.t(message),
        description: i18n.t(description),
        duration: seconds,
    });
};

const openErrorNotification = (message, description, seconds = 2) => {
    notification["error"]({
        message: i18n.t(message),
        description: i18n.t(description),
        duration: seconds,
    });
};

export { openSuccessNotification, openWarningNotification, openErrorNotification };
