import { Empty } from "antd";
import { withTranslation } from "react-i18next";
import withErrorHandler from "../../util/WithErrorHandler";

const NoData = (props) => {
    return (
        <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={props.description ? <span> {props.description}</span> : <span> {props.t("No Data")}</span>}
        ></Empty>
    );
};

export default withErrorHandler(withTranslation()(NoData));
