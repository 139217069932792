import { useEffect, useMemo, useRef, useState } from "react";

export default function useModalControl({ onOpen, onCancel, onSubmit } = {}) {
    const [modalVisible, setModalVisible] = useState(false);
    const isMounted = useRef(true);
    const propsRef = useRef({ onOpen, onCancel, onSubmit });
    propsRef.current = { onOpen, onCancel, onSubmit };

    useEffect(() => {
        //Component unMount
        return () => {
            isMounted.current = false;
        };
    }, []);

    return useMemo(
        () => ({
            modalVisible: modalVisible,
            open: async (...params) => {
                propsRef.current?.onOpen && (await propsRef.current.onOpen(...params));
                isMounted.current && setModalVisible(true);
            },
            onCancel: async (...params) => {
                propsRef.current?.onCancel && (await propsRef.current.onCancel(...params));
                isMounted.current && setModalVisible(false);
            },
            onSubmit: async (...params) => {
                propsRef.current?.onSubmit && (await propsRef.current.onSubmit(...params));
                isMounted.current && setModalVisible(false);
            },
        }),
        [modalVisible]
    );
}
