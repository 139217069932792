import { ConfigProvider, Layout, Spin } from "antd";
import { Route, Redirect, Switch, useHistory } from "react-router-dom";
import LoginPage from "./component/login/LoginPage";
import DashboardPage from "./component/dashboard/DashboardPage";
import ReportPage from "./component/report/ReportPage";
import NavigationMenu from "./component/common/NavigationMenu";
import NoData from "./component/common/NoData";
import { withTranslation } from "react-i18next";
import { useThemeSwitcher } from "react-css-theme-switcher";
import "./App.less";
import { KuklaProdiver } from "./context/KuklaContext";
import { useUserContext } from "./context/UserContext";
import DashTimers from "./DashTimers";
import { ProjectProvider } from "./context/ProjectContext";
import useLocalStorage from "./hook/UseLocalStorage";
import { useEffect, Suspense, lazy } from "react";
import withErrorHandler from "./util/WithErrorHandler";
import RunResultModal from "./component/common/RunResultModal";
import BrowserTabTitle from "./component/common/BrowserTabTitle";
const UserSettingsPage = lazy(() => import("./component/userSettings/UserSettingsPage"));
const ProjectPage = lazy(() => import("./component/project/ProjectPage"));
const ProjectListPage = lazy(() => import("./component/projectList/ProjectListPage"));
const SettingsPage = lazy(() => import("./component/settings/SettingsPage"));
const HelpFrame = lazy(() => import("./component/help/HelpFrame"));
const JobPage = lazy(() => import("./component/job/JobPage"));

const { Content } = Layout;

const App = () => {
    const { user } = useUserContext();
    let history = useHistory();
    // theme related
    const { switcher, currentTheme, status, themes } = useThemeSwitcher();
    const [themeStr, setThemeStr] = useLocalStorage("theme", currentTheme);
    useEffect(() => {
        if (themeStr) {
            // themStr is null when localstorage is disabled
            switcher({ theme: themeStr === "dark" ? themes.dark : themes.light });
        }
    }, [switcher, themeStr, themes.dark, themes.light]);

    // Avoid theme change flicker
    if (status !== "loaded") {
        return null;
    }

    if (user == null) {
        return (
            <Layout>
                <BrowserTabTitle />
                <ConfigProvider renderEmpty={() => <NoData />}>
                    <Route
                        exact
                        path="/"
                        render={(props) => (
                            <Content className="content">
                                <LoginPage {...props} theme={currentTheme} />
                            </Content>
                        )}
                    />
                    <Redirect to="/" />
                </ConfigProvider>
            </Layout>
        );
    } else {
        return (
            <Layout className="layout">
                <BrowserTabTitle />
                <ConfigProvider renderEmpty={() => <NoData />}>
                    <KuklaProdiver>
                        <DashTimers />
                        <NavigationMenu />
                        <Suspense fallback={<Spin className="loader" />}>
                            <Switch>
                                <Route exact path="/dashboard" render={(props) => <DashboardPage {...props} />} />
                                <Route
                                    path={[
                                        "/projects",
                                        "/projects/:projectId-:projectName",
                                        "/projects/:projectId-:projectName/:menuKey",
                                    ]}
                                    render={() => (
                                        <>
                                            <Route
                                                exact
                                                path="/projects"
                                                render={(props) => <ProjectListPage {...props} />}
                                            />

                                            <Route
                                                exact
                                                path={[
                                                    "/projects/:projectId-:projectName/:menuKey",
                                                    "/projects/:projectId-:projectName",
                                                ]}
                                                render={(props) => (
                                                    <ProjectProvider
                                                        _projectId={props.match.params.projectId}
                                                        _projectName={props.match.params.projectName}
                                                    >
                                                        <ProjectPage {...props} menuKey={props.match.params.menuKey} />
                                                    </ProjectProvider>
                                                )}
                                            />
                                        </>
                                    )}
                                />

                                <Route
                                    exact
                                    path={["/jobs", "/jobs/:jobId", "/jobs/:jobId/edit"]}
                                    render={(props) => <JobPage {...props} />}
                                />
                                <Route
                                    exact
                                    path={["/admin", "/admin/:menuKey","/admin/:menuKey/:roleId"]}
                                    render={(props) => <SettingsPage {...props} menuKey={props.match.params.menuKey} />}
                                />
                                <Route
                                    exact
                                    path={["/reports", "/reports/:menuKey"]}
                                    render={(props) => <ReportPage {...props} menuKey={props.match.params.menuKey} />}
                                />
                                <Route
                                    exact
                                    path="/run-list/:runId"
                                    render={(props) => (
                                        <RunResultModal
                                            {...props}
                                            runId={props.match.params.runId}
                                            local={false}
                                            visible={true}
                                            hideCallback={() => history.push("/jobs")}
                                        />
                                    )}
                                />
                                <Route
                                    exact
                                    path={["/settings", "/settings/:menuKey"]}
                                    render={(props) => (
                                        <UserSettingsPage
                                            menuKey={props.match.params.menuKey}
                                            setThemeStr={setThemeStr}
                                            {...props}
                                        />
                                    )}
                                />
                                <Route exact path="/help" render={() => <HelpFrame />} />
                                <Redirect to="/dashboard" />
                            </Switch>
                        </Suspense>
                    </KuklaProdiver>
                </ConfigProvider>
            </Layout>
        );
    }
};

export default withErrorHandler(withTranslation()(App));
