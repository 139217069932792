import { Row, Col, PageHeader, Menu } from "antd";
import { Content } from "antd/lib/layout/layout";
import { Link, Redirect, Route, Switch } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import withErrorHandler from "../../util/WithErrorHandler";
import ReportTable from "./ReportTable";

// TODO : fix -> return empty list from backend if there is no report for selected groups/project/jobs
const ReportPage = (props) => {
    const [selectedKeys, setSelectedKeys] = useState([]);
    const [reportsLoaded, setReportsLoaded] = useState(false);

    useEffect(() => {
        setSelectedKeys([props.menuKey ? props.menuKey : "users"]);
    }, [props.menuKey]);

    const onMenuClick = (item) => {
        setReportsLoaded(false);
        setSelectedKeys([item.key]);
    };
    return (
        <>
            <Content className="padded-content-left">
                <Row className="full-height">
                    <Col className="left-side-container">
                        <div className="flex-justify-between left-side-header">
                            <PageHeader title={props.t("Reports")} />
                        </div>
                        <Menu className="settings-side-menu" onClick={onMenuClick} selectedKeys={selectedKeys}>
                            <Menu.Item key="bug" disabled={!reportsLoaded}>
                                <Link to={{ pathname: "/reports/bug" }}>{props.t("Bug Report")}</Link>
                            </Menu.Item>
                            <Menu.Item key="run" disabled={!reportsLoaded}>
                                <Link to={{ pathname: "/reports/run" }}>{props.t("Run Report")}</Link>
                            </Menu.Item>
                            <Menu.Item key="local" disabled={!reportsLoaded}>
                                <Link to={{ pathname: "/reports/local" }}>{props.t("Local Report")}</Link>
                            </Menu.Item>
                        </Menu>
                    </Col>
                    <Col className="right-content-container">
                        <Switch>
                            <Route
                                exact
                                path="/reports/bug"
                                render={(props) => <ReportTable {...props} type="bug" setLoaded={setReportsLoaded} />}
                            />
                            <Route
                                exact
                                path="/reports/run"
                                render={(props) => <ReportTable {...props} type="run" setLoaded={setReportsLoaded} />}
                            />
                            <Route
                                exact
                                path="/reports/local"
                                render={(props) => <ReportTable {...props} type="local" setLoaded={setReportsLoaded} />}
                            />
                            <Redirect to="/reports/bug" />
                        </Switch>
                    </Col>
                </Row>
            </Content>
        </>
    );
};

export default withErrorHandler(withTranslation()(ReportPage));
