import { ThemeSwitcherProvider } from "react-css-theme-switcher";
import useLocalStorage from "./hook/UseLocalStorage";

const themes = {
    dark: `${process.env.PUBLIC_URL}/themes/dark-theme-${process.env.REACT_APP_BUILD_TIME}.css`,
    light: `${process.env.PUBLIC_URL}/themes/light-theme-${process.env.REACT_APP_BUILD_TIME}.css`,
};

const AppThemeProvider = ({ children }) => {
    const browserTheme = window.matchMedia("(prefers-color-scheme: light)").matches ? "light" : "dark";
    const [theme] = useLocalStorage("theme", browserTheme);

    return (
        <ThemeSwitcherProvider
            themeMap={themes}
            defaultTheme={theme}
            insertionPoint={document.getElementById("inject-styles-here")}
        >
            {children}
        </ThemeSwitcherProvider>
    );
};

export default AppThemeProvider;
